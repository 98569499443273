// noinspection DuplicatedCode
import { Action, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance, { setAccessToken } from '../../api'
import { ROLES } from '../../constants'
import { IAuthUser } from '../index'

export const AUTH = 'auth'

export const getToken = createAsyncThunk(`${AUTH}/getToken`, () => {
	const user = localStorage.getItem('user')
	const token = localStorage.getItem('token')
	const refreshToken = localStorage.getItem('refresh_token')
	if (!token || !user || !refreshToken) return
	setAccessToken(token)
	return { ...JSON.parse(user), refreshToken }
})

const saveUserPermanently = (user: IAuthUser) => {
	localStorage.setItem('token', user.token || '')
	localStorage.setItem('user', JSON.stringify(user))
	localStorage.setItem('refresh_token', user.refresh_token || '')
	setAccessToken(user.token)
}

export const login = createAsyncThunk(
	`${AUTH}/login`,
	async ({ email, password }: { email: string; password: string }) => {
		return await new Promise<IAuthUser>((resolve, reject) => {
			axiosInstance
				.post('auth/login', {
					username: email,
					password: password
				})
				.then(res => {
					if (res.data.status !== 'active') {
						return reject('Пользователь заблокирован')
					}
					const roles: Record<string, 'super_admin' | 'client_admin' | 'user'> =
						{
							super_admin: ROLES.SUPER_ADMIN,
							// "admin": ROLES.SUPER_ADMIN,
							client_admin: ROLES.CLIENT_ADMIN,
							user: ROLES.USER
						}
					const user: IAuthUser = {
						id: res.data.id,
						name: res.data.full_name,
						status: res.data.status,
						role: roles[res.data.user_type] ?? ROLES.USER,
						token: res.data.access_token,
						refresh_token: res.data.refresh_token,
						telegram_id: res.data.telegram_id,
						telegram_chat_id: res.data.telegram_chat_id
					}
					saveUserPermanently(user)
					resolve(user)
				})
				.catch(err => {
					switch (err.response.status) {
						case 401:
							reject('Неизвестный логин или неправильный пароль')
							break
						default:
							console.error(err)
							throw new Error('Ошибка сервера!')
					}
				})
		})
	}
)

export const refreshAccessToken = createAsyncThunk(
	`${AUTH}/refreshAccessToken`,
	async (refreshToken: string, { rejectWithValue }) => {
		try {
			const res = await axiosInstance.post('auth/refresh', {
				refresh_token: refreshToken
			})

			const roles: Record<string, 'super_admin' | 'client_admin' | 'user'> = {
				super_admin: ROLES.SUPER_ADMIN,
				// "admin": ROLES.SUPER_ADMIN,
				client_admin: ROLES.CLIENT_ADMIN,
				user: ROLES.USER
			}
			const user: IAuthUser = {
				id: res.data.id,
				name: res.data.full_name,
				status: res.data.status,
				role: roles[res.data.user_type] ?? ROLES.USER,
				token: res.data.access_token,
				refresh_token: res.data.refresh_token,
				telegram_id: res.data.telegram_id,
				telegram_chat_id: res.data.telegram_chat_id
			}
			saveUserPermanently(user)
			return res.data.access_token
		} catch (error) {
			console.error('Ошибка обновления токена', error)
			return rejectWithValue('Не удалось обновить токен')
		}
	}
)

export const logoutUser = () => {
	localStorage.removeItem('token')
	localStorage.removeItem('refresh_token')
	localStorage.removeItem('user')
}

export const logout = createAsyncThunk(`${AUTH}/logout`, logoutUser)

const loginReducer = (
	state: IAuthState,
	action: Action & { payload: IAuthUser | null }
) => {
	if (!action.payload) return
	state.entity = action.payload
	state.loading = false
}

const loadingReducer = (state: IAuthState) => {
	state.loading = true
}

export interface IAuthState {
	entity: IAuthUser | null
	loading: boolean
	tokenLoading: boolean
}

const initialState: IAuthState = {
	entity: null,
	loading: false,
	tokenLoading: true
}

const authSlice = createSlice({
	name: AUTH,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(login.pending, loadingReducer)
			.addCase(login.fulfilled, loginReducer)
			.addCase(getToken.fulfilled, (state, action) => {
				state.tokenLoading = false
				return loginReducer(state, action)
			})
			.addCase(logout.fulfilled, state => {
				state.entity = null
			})
	}
})
export default authSlice.reducer
