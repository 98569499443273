import cn from 'classnames'
import React from 'react'

import styles from './index.module.scss'

const Checkbox = ({ labelText, className, onChange, ...props }) => {
	return (
		<label className={cn(styles.Label, className)}>
			{labelText && <span>{labelText}</span>}
			{props.checked}
			<input
				{...props}
				className={styles.Input}
				type='checkbox'
				onChange={e => onChange(e.target.checked)}
			/>
		</label>
	)
}

export default React.memo(Checkbox)
