import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons'
import React from 'react'

import styles from './index.module.scss'

const Table = ({
	data,
	total,
	columns,
	actions,
	page,
	pageSize = 10,
	setPage
}) => {
	// const [currentPage, setCurrentPage] = useState(1);
	// const itemsPerPage = 10;

	let totalPages = Math.floor(total / pageSize)
	if (totalPages * pageSize < total) ++totalPages

	// const indexOfLastItem = page * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = data //data.slice(indexOfFirstItem, indexOfLastItem);

	const fieldValue = (item, column) =>
		typeof column.value == 'function' ? column.value(item) : item[column.key]
	const paginate = pageNumber => setPage(pageNumber)

	return (
		<div className={styles.TableContainer}>
			<div className={styles.Table}>
				<div className={styles.TableHeader}>
					{columns.map(column => (
						<div key={column.key} className={styles.ColumnHeader}>
							{column.displayName}
						</div>
					))}
					{actions && <div className={styles.ActionsColumn}>Действия</div>}
				</div>
				<div className={styles.TableBody}>
					{currentItems.map(item => (
						<div key={item.id} className={styles.Row}>
							{columns.map(column => (
								<div key={column.key} className={styles.Cell}>
									{fieldValue(item, column)}
								</div>
							))}
							{actions && (
								<div className={styles.ActionsCell}>
									{actions.map(a => {
										if (typeof a == 'function') {
											a = a(item)
										}
										return a ? (
											<button
												className={styles.EditButton}
												title={a.title}
												onClick={() => a.handle(item.id)}
												key={a.key}
											>
												{a.icon}
											</button>
										) : undefined
									})}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
			{totalPages > 1 && (
				<div className={styles.Pagination}>
					<button
						onClick={() => paginate(page - 1)}
						disabled={page === 1}
						className={styles.NavigationButton}
					>
						<UilAngleLeft size='20' color='white' />
					</button>

					<div className={styles.PaginationButtons}>
						{/* Первая страница */}
						<button
							onClick={() => paginate(1)}
							className={
								page === 1
									? `${styles.PaginationButton} ${styles.active}`
									: styles.PaginationButton
							}
						>
							1
						</button>

						{/* Левая часть: если страница больше 4, показываем троеточие */}
						{page > 4 && <span className={styles.PaginationDots}>...</span>}

						{/* Две страницы перед текущей */}
						{Array.from({ length: 2 }, (_, i) => page - 2 + i)
							.filter(p => p > 1 && p < totalPages)
							.map(p => (
								<button
									key={p}
									onClick={() => paginate(p)}
									className={
										page === p
											? `${styles.PaginationButton} ${styles.active}`
											: styles.PaginationButton
									}
								>
									{p}
								</button>
							))}

						{/* Текущая страница */}
						{page !== 1 && page !== totalPages && (
							<button className={`${styles.PaginationButton} ${styles.active}`}>
								{page}
							</button>
						)}

						{/* Две страницы после текущей */}
						{Array.from({ length: 2 }, (_, i) => page + i + 1)
							.filter(p => p < totalPages)
							.map(p => (
								<button
									key={p}
									onClick={() => paginate(p)}
									className={
										page === p
											? `${styles.PaginationButton} ${styles.active}`
											: styles.PaginationButton
									}
								>
									{p}
								</button>
							))}

						{/* Правая часть: если есть страница после текущей + 2, показываем троеточие */}
						{page < totalPages - 3 && (
							<span className={styles.PaginationDots}>...</span>
						)}

						{/* Последняя страница */}
						{totalPages > 1 && (
							<button
								onClick={() => paginate(totalPages)}
								className={
									page === totalPages
										? `${styles.PaginationButton} ${styles.active}`
										: styles.PaginationButton
								}
							>
								{totalPages}
							</button>
						)}
					</div>

					<button
						onClick={() => paginate(page + 1)}
						disabled={page === totalPages}
						className={styles.NavigationButton}
					>
						<UilAngleRight size='20' color='white' />
					</button>
				</div>
			)}
		</div>
	)
}

export default React.memo(Table)
