const formatDate = (dateString: number) => {
	const date = new Date(dateString * 1000)
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric'
		// timeZone: 'UTC'
	}
	return date.toLocaleDateString('ru-RU', options)
}

export default formatDate
