import cn from 'classnames'
import React from 'react'

import styles from './index.module.scss'

const Input = ({ type = 'text', labelText, className, onChange, ...props }) => {
	return (
		<label className={cn(styles.Label, className)}>
			{labelText && <span>{labelText}</span>}
			<input
				{...props}
				className={styles.Input}
				type={type}
				onChange={e => onChange(e.target.value)}
			/>
		</label>
	)
}

export default React.memo(Input)
