import { UilTimesCircle } from '@iconscout/react-unicons'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'

import Button from '../Button'
import DateRangeInput from '../DateRangeInput'
import Select from '../Select'

import styles from './index.module.scss'

export interface FilterProps {
	filters: Filter[]
	onChange?: (filters: SelectedFilters) => void
	applyFilters: (filters: SelectedFilters) => void
}

export enum FilterType {
	Select = 'select',
	DateRange = 'dateRange'
}

interface BaseFilter {
	label: string
	field: string
	type: FilterType
}

export interface SelectFilter extends BaseFilter {
	type: FilterType.Select
	options: { label: string; value: string }[]
}

export interface DateRangeFilter extends BaseFilter {
	type: FilterType.DateRange
}

export type Filter = SelectFilter | DateRangeFilter
export type SelectedFilters = Record<string, string | Date | null>

const FilterComponent: React.FC<FilterProps> = ({
	filters,
	onChange,
	applyFilters /*,selectedFilters,setSelectedFilters*/
}) => {
	const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({})

	const handleFilterChange = (field: string, value: string | Date | null) => {
		setSelectedFilters((prevState: SelectedFilters) => ({
			...prevState,
			[field]: value
		}))
	}

	const handleApplyFilters = () => {
		applyFilters(selectedFilters)
	}

	useEffect(() => {
		onChange?.(selectedFilters)
	}, [selectedFilters])

	const resetFilters = () => {
		setSelectedFilters({})
		applyFilters({})
		onChange?.({})
	}

	return (
		<div className={styles.FilterContainer}>
			<div className={styles.FilterFields}>
				{filters.map((filter, index) => (
					<div key={index} className={styles.FilterItem}>
						{filter.type === FilterType.Select && (
							<Select
								options={(filter as SelectFilter).options}
								value={selectedFilters[filter.field]}
								onChange={(value: string) =>
									handleFilterChange(filter.field, value)
								}
								placeholder='Выберите'
								labelText={filter.label}
								isCalculatedWidth
							/>
						)}
						{filter.type === FilterType.DateRange && (
							<DateRangeInput
								labelText={filter.label}
								startDate={
									selectedFilters[`${filter.field}-start`] as Date | null
								}
								endDate={selectedFilters[`${filter.field}-end`] as Date | null}
								onChangeStartDate={date =>
									handleFilterChange(`${filter.field}-start`, date)
								}
								onChangeEndDate={date =>
									handleFilterChange(`${filter.field}-end`, date)
								}
							/>
						)}
					</div>
				))}
			</div>
			<Button
				onClick={handleApplyFilters}
				to={undefined}
				icon={undefined}
				className={undefined}
				uppercase={undefined}
				loading={undefined}
			>
				Применить
			</Button>
			<UilTimesCircle
				className={styles.Icon}
				color='#e7682a'
				onClick={resetFilters}
			/>
		</div>
	)
}

export default React.memo(FilterComponent)
