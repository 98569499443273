export const ROLES = {
	SUPER_ADMIN: 'super_admin',
	CLIENT_ADMIN: 'client_admin',
	USER: 'user'
} as const
export const ROLES_TITLES = {
	[ROLES.SUPER_ADMIN]: 'Системный администратор',
	[ROLES.CLIENT_ADMIN]: 'Администратор',
	[ROLES.USER]: 'Пользователь'
} as const
export const STATUSES = { ACTIVE: 'active', INACTIVE: 'not_active' } as const
export const STATUSES_TITLES = {
	[STATUSES.ACTIVE]: 'Активен',
	[STATUSES.INACTIVE]: 'Деактивирован'
} as const
