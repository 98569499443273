import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../api'

export const CONSTANTS = 'constants'

export const loadConsts = createAsyncThunk(`${CONSTANTS}/load`, async () => {
	return await new Promise((resolve, reject) => {
		axiosInstance
			.get('constants/all')
			.then((res: any) => {
				resolve(res.data)
			})
			.catch(reject)
	})
})

type REF = Record<string, string>

interface IConstants {
	loading: boolean
	user_status: REF
	user_type: REF
	request_type: REF
	report_method: REF
	package_type: REF
	request_source: REF
	package_status: REF
	package_option: REF
	company_status: REF
	tg_bots: REF
	request_status: REF
	request_method: REF
	request_standard: Record<any, any>
	request_status_description: REF
}

const constants: IConstants = {
	loading: true,
	user_status: {},
	user_type: {},
	request_type: {},
	report_method: {},
	package_type: {},
	request_source: {},
	package_status: {},
	package_option: {},
	company_status: {},
	tg_bots: {},
	request_status: {},
	request_method: {},
	request_standard: {},
	request_status_description: {}
}

const constantsSlice = createSlice({
	name: CONSTANTS,
	initialState: constants,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(loadConsts.fulfilled, (state, action) => {
			Object.keys(constants).forEach(
				//@ts-ignore
				key => (state[key] = action.payload[key] || {})
			)
			state.loading = false
		})
	},
	selectors: {
		getUserStatuses: state => state.user_status,
		getUserTypes: state => state.user_type,
		getRequestTypes: state => state.request_type,
		getRequestMethod: state => state.request_method,
		getRequestStandard: state => state.request_standard,
		getPackageTypes: state => state.package_type,
		getPackageStatuses: state => state.package_status,
		getPackageOptions: state => state.package_option,
		getCompanyStatuses: state => state.company_status,
		getTGBots: state => state.tg_bots,
		getRequestStatuses: state => state.request_status,

		getUserStatus: state => (status: string) =>
			state.user_status[status] || status,
		getUserType: state => (type: string) => state.user_type[type] || type,
		getRequestType: state => (type: string) => state.request_type[type] || type,
		getReportMethods: state => state.report_method,
		getReportMethod: state => (type: string) => state.report_method[type] || type,
		getPackageType: state => (type: string) => state.package_type[type] || type,
		getPackageStatus: state => (status: string) =>
			state.package_status[status] || status,
		getPackageOption: state => (option: string) =>
			state.package_option[option] || option,
		getCompanyStatus: state => (status: string) =>
			state.company_status[status] || status,
		getTGBot: state => (bot: string) => state.tg_bots[bot] || bot,
		getRequestStatus: state => (status: string) =>
			state.request_status[status] || status,
		getRequestStatusDescription: state => (option: string) =>
			state.request_status_description[option] || option,
		getRequestSource: state => (type: string) =>
			state.request_source[type] || type
	}
})

export default constantsSlice.reducer

export const {
	getUserStatus,
	getUserType,
	getRequestType,
	getReportMethod,
	getReportMethods,
	getPackageType,
	getPackageStatus,
	getPackageOption,
	getCompanyStatus,
	getTGBot,
	getUserStatuses,
	getUserTypes,
	getRequestTypes,
	getRequestMethod,
	getRequestStandard,
	getPackageTypes,
	getPackageStatuses,
	getPackageOptions,
	getCompanyStatuses,
	getTGBots,
	getRequestStatuses,
	getRequestStatus,
	getRequestStatusDescription,
	getRequestSource
} = constantsSlice.selectors
