import React, { useContext } from 'react'

import Form from '../../../../primitives/Form'
import { StructuredSearchContext } from '../../index'

const FIELDS = [
	// {
	//     name: "legal-name",
	//     labelText: "Наименование организации",
	//     placeholder: "ООО \"Инфорензик\"",
	// },
	{
		name: 'requisites',
		children: [
			{ name: 'company_tax_id', labelText: 'Реквизиты', placeholder: 'ИНН' },
			{ name: 'company_prsn', placeholder: 'ОГРН' }
		]
	}
]

const VALIDATE_COMBINATIONS = [
	// [{name: 'name', errorLabel: 'Наименование организации'}],
	[{ name: 'company_tax_id', errorLabel: 'ИНН' }],
	[{ name: 'company_prsn', errorLabel: 'ОГРН' }]
] as never[]

const Legal = () => {
	const { handleStructuredReport } = useContext(StructuredSearchContext)

	return (
		<div>
			<Form
				fields={FIELDS}
				onSubmit={handleStructuredReport}
				buttonText='Искать'
				onChange={undefined}
				headerText={undefined}
				buttonIcon={undefined}
				column={undefined}
				className={undefined}
				validateCombinations={VALIDATE_COMBINATIONS}
			/>
		</div>
	)
}

export default React.memo(Legal)
