import React from 'react'

import styles from './index.module.scss'

const LoaderSpinner = () => {
	return (
		<div className={styles.SpinnerContainer}>
			<div className={styles.Spinner} />
		</div>
	)
}

export default React.memo(LoaderSpinner)
