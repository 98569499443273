import React, { createContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import appStyles from '../../App.module.scss'
import SearchTable from '../../components/SearchTable'
import Tabs from '../../components/Tabs'
import { ROUTES_PATHS, ROUTES_TITLES } from '../../router/routes'
import { AppDispatch, IRootState } from '../../store'
import { SEARCH, getStructuredReport } from '../../store/slices/search'
import { StructuredReportNames } from '../../store/slices/search/types'
import styles from '../Search/index.module.scss'

import { FormValues } from './components/Individual'

const onlyContext = process.env.REACT_APP_ONLY_CONTEXT

export const StructuredSearchContext = createContext<{
	handleStructuredReport: ReturnType<typeof handleStructuredReport> | Function
}>({
	handleStructuredReport: () => {}
})

const handleStructuredReport = (dispatch: AppDispatch) => {
	return (values: FormValues<StructuredReportNames>) => {
		const path = window.location.pathname.split('/')[2]
		const filteredValues: { [key in StructuredReportNames]?: any } = {}
		Object.entries(values).forEach(([name, value]) => {
			if (value) filteredValues[name as StructuredReportNames] = value
		})
		// @ts-ignore
		return dispatch(getStructuredReport({ structured: filteredValues, path }))
	}
}

const StructuredSearch = () => {
	const dispatch = useDispatch<AppDispatch>()
	const report = useSelector<IRootState, Object | null>(
		state => state[SEARCH].structured.entity
	)
	const [page, setPage] = useState(1)
	const authUser = useSelector((state: IRootState) => state['auth'].entity)
	const structuredReport = ['lpr', 'lpr-mass', 'standard'].includes(
		window.location.pathname.split('/')[2]
	)

	const tabs = useMemo(() => {
		let SHOW_TABS = [];

		if (onlyContext === '1') {
			SHOW_TABS = [{ path: 'standard', title: 'Стандартный' }];
		} else {
			SHOW_TABS = [
				{ path: 'individual', title: 'Физическое лицо' },
				{ path: 'legal', title: 'Организация' },
				{ path: 'vehicle', title: 'Транспорт' },
				{ path: 'lpr', title: 'ЛПР' },
				{ path: 'lpr-mass', title: 'ЛПР массовый' },
				{ path: 'standard', title: 'Стандартный' }
			];
		}

		return SHOW_TABS;
	}, [onlyContext]); // Зависимость от onlyContext

	return (
		<StructuredSearchContext.Provider
			value={{
				handleStructuredReport: handleStructuredReport(dispatch)
			}}
		>
			<h2 className={appStyles.MobileTitle}>
				{ROUTES_TITLES[ROUTES_PATHS.STRUCTURED_SEARCH.INDEX]}
			</h2>
			<Tabs tabs={tabs} />
			<div className={styles.Search}>
				<Outlet />
				{report && (
					<SearchTable
						page={page}
						structuredReport={structuredReport}
						setPage={setPage}
						authUser={authUser}
					/>
				)}
				{/*{structuredState.entity && <IframeRenderer htmlContent={structuredState.entity}/>}*/}
			</div>
		</StructuredSearchContext.Provider>
	)
}

export default React.memo(StructuredSearch)
