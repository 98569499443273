import cn from 'classnames'
import React from 'react'

import styles from './index.module.scss'

const File = ({ labelText, className, onChange, ...props }) => {
	const { value: _, ...filteredProps } = props

	return (
		<label className={cn(styles.Label, className)}>
			{labelText && <span>{labelText}</span>}
			<input
				{...filteredProps}
				className={styles.Input}
				type='file'
				onChange={e => {
					const formData = new FormData()
					formData.append('file', e.target.files[0])
					onChange(formData)
				}}
			/>
		</label>
	)
}

export default React.memo(File)
