import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Form from '../../../../primitives/Form'
import { getReportMethods } from '../../../../store/slices/constants'
import { StructuredReportNames } from '../../../../store/slices/search/types'
import { StructuredSearchContext } from '../../index'

export type FormValues<T extends string | number | symbol> = {
	[K in T]: string
}

const LPRMass = () => {
	const { handleStructuredReport } = useContext(StructuredSearchContext)
	const reportMethods = useSelector(getReportMethods)

	const handleSubmit = useCallback(
		(values: FormValues<StructuredReportNames>) => {
			return handleStructuredReport(values)
		},
		[handleStructuredReport]
	)

	const FIELDS = useMemo(
		() => [
			{
				name: 'requisites',
				children: [
					{
						name: 'file',
						labelText: 'Реквизиты',
						placeholder: 'Файл',
						type: 'file'
					},
					{
						name: 'method',
						placeholder: 'Метод',
						type: 'select',
						options: Object.keys(reportMethods).map(key => ({
							value: key,
							label: reportMethods[key]
						}))
					}
				]
			}
		],
		[reportMethods]
	)

	const VALIDATE_COMBINATIONS = [
		[{ name: 'file', errorLabel: 'Файл' }],
		[{ name: 'method', errorLabel: 'Метод' }]
	] as never[]

	return (
		<div>
			<Form
				fields={FIELDS}
				onSubmit={handleSubmit}
				buttonText='Искать'
				headerText={undefined}
				onChange={undefined}
				buttonIcon={undefined}
				column={undefined}
				className={undefined}
				validateCombinations={VALIDATE_COMBINATIONS}
			/>
		</div>
	)
}

export default React.memo(LPRMass)
