function parseJwt(token: string) {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)

	return JSON.parse(jsonPayload)
}

function isTokenExpiringSoon(token: string, thresholdMinutes = 0) {
	const { exp } = parseJwt(token)
	const currentTime = Math.floor(Date.now() / 1000) // Текущее время в секундах
	const expTime = exp - thresholdMinutes * 60 // Время истечения минус threshold
	return expTime < currentTime
}

export default isTokenExpiringSoon
