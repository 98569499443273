import { Navigate } from 'react-router-dom'

import NotFoundError from '../components/NotFoundError'
import Auth from '../pages/Auth'
import Clients from '../pages/Clients'
import ForgotPassword from '../pages/ForgotPassword'
import Packages from '../pages/Packages'
import Report from '../pages/Report'
import Reports from '../pages/Reports'
import Search from '../pages/Search'
import StructuredSearch from '../pages/StructuredSearch'
import Individual from '../pages/StructuredSearch/components/Individual'
import LPR from '../pages/StructuredSearch/components/LPR'
import LPRMass from '../pages/StructuredSearch/components/LPRMass'
import Legal from '../pages/StructuredSearch/components/Legal'
import Standard from '../pages/StructuredSearch/components/Standard'
import Vehicle from '../pages/StructuredSearch/components/Vehicle'
import Users from '../pages/Users'

export const ROUTES_PATHS = {
	HOME: '/',
	SEARCH: '/search',
	STRUCTURED_SEARCH: {
		INDEX: '/structured-search',
		STANDARD: 'standard',
		INDIVIDUAL: 'individual',
		LEGAL: 'legal',
		VEHICLE: 'vehicle',
		LPR: 'lpr',
		LPR_MASS: 'lpr-mass'
	},
	STATS: '/stats',
	USERS: '/users',
	CLIENTS: '/clients',
	PACKAGES: '/packages',
	REPORTS: {
		INDEX: '/reports',
		FACTORY: 'factory',
		STANDARD: 'standard',
		LPR: 'lpr-by-inn',
		LPR_MASS: 'lpr-mass'
	},
	REPORT: '/report',
	AUTH: '/auth',
	FORGOT_PASSWORD: '/forgot-password',
	NOT_FOUND: '*'
}

export const ROUTES_TITLES = {
	[ROUTES_PATHS.SEARCH]: 'Поиск',
	[ROUTES_PATHS.STRUCTURED_SEARCH.INDEX]: 'Структурированный поиск',
	[ROUTES_PATHS.USERS]: 'Пользователи',
	[ROUTES_PATHS.CLIENTS]: 'Клиенты',
	[ROUTES_PATHS.PACKAGES]: 'Пакеты',
	[ROUTES_PATHS.REPORTS]: 'История отчетов'
}

export const HOME_ROUTES = [
	{ path: ROUTES_PATHS.HOME, element: <Navigate to={ROUTES_PATHS.SEARCH} /> },
	{ path: ROUTES_PATHS.AUTH, Component: Auth },
	{ path: ROUTES_PATHS.FORGOT_PASSWORD, Component: ForgotPassword },
	{ path: ROUTES_PATHS.SEARCH, Component: Search },
	{
		path: ROUTES_PATHS.STRUCTURED_SEARCH.INDEX,
		Component: StructuredSearch,
		children: [
			{
				index: true,
				element:  process.env.REACT_APP_ONLY_CONTEXT === '1'
					? <Navigate to={ROUTES_PATHS.STRUCTURED_SEARCH.STANDARD} />
					: <Navigate to={ROUTES_PATHS.STRUCTURED_SEARCH.INDIVIDUAL} />
			},
			{ path: ROUTES_PATHS.STRUCTURED_SEARCH.STANDARD, Component: Standard },
			{ path: ROUTES_PATHS.STRUCTURED_SEARCH.LEGAL, Component: Legal },
			{
				path: ROUTES_PATHS.STRUCTURED_SEARCH.INDIVIDUAL,
				Component: Individual
			},
			{ path: ROUTES_PATHS.STRUCTURED_SEARCH.VEHICLE, Component: Vehicle },
			{ path: ROUTES_PATHS.STRUCTURED_SEARCH.LPR, Component: LPR },
			{ path: ROUTES_PATHS.STRUCTURED_SEARCH.LPR_MASS, Component: LPRMass },
		]
	},
	{ path: ROUTES_PATHS.USERS, Component: Users },
	{ path: ROUTES_PATHS.CLIENTS, Component: Clients },
	{ path: ROUTES_PATHS.PACKAGES, Component: Packages },
	{
		path: ROUTES_PATHS.REPORTS.INDEX,
		Component: Reports,
		children: [
			{
				index: true,
				element: <Navigate to={ROUTES_PATHS.REPORTS.FACTORY} />
			},
			{ path: ROUTES_PATHS.REPORTS.FACTORY, Component: Reports },
			{
				path: ROUTES_PATHS.REPORTS.STANDARD,
				Component: Reports
			},
			{ path: ROUTES_PATHS.REPORTS.LPR, Component: Reports },
			{ path: ROUTES_PATHS.REPORTS.LPR_MASS, Component: Reports }
		]
	},
	{ path: ROUTES_PATHS.REPORT, Component: Report },
	{ path: ROUTES_PATHS.NOT_FOUND, Component: NotFoundError }
]
