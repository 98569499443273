import { UilAngleDown } from '@iconscout/react-unicons'
import cn from 'classnames'
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState
} from 'react'

import useClickOutside from '../../hooks/useClickOutside'

import styles from './index.module.scss'

const Select = ({
	options,
	value,
	onChange,
	placeholder = 'Выберите',
	labelText,
	isCalculatedWidth
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const ref = useRef()
	const selectRef = useRef(null)
	const optionsRef = useRef(null)

	const [shouldFocus, setShouldFocus] = useState(false)
	const [selectWidth, setSelectWidth] = useState('auto')

	useLayoutEffect(() => {
		if (!isCalculatedWidth) return

		const maxWidth = options.reduce((max, option) => {
			const optionWidth = measureTextWidth(option.label)
			return Math.max(max, optionWidth)
		}, 0)

		if (maxWidth > 0) {
			setSelectWidth(maxWidth + 24 + 'px')
		}
	}, [options, isCalculatedWidth])

	const handleToggle = useCallback(() => {
		setIsOpen(prev => !prev)
	}, [])

	const handleOptionClick = useCallback(
		optionValue => {
			onChange(optionValue)
			setIsOpen(false)
			setShouldFocus(true)
		},
		[onChange]
	)

	const close = useCallback(() => {
		setIsOpen(false)
		setShouldFocus(true)
	}, [])

	useClickOutside({
		elementRef: ref,
		excludeRefs: [optionsRef],
		callback: close
	})

	useEffect(() => {
		if (shouldFocus) {
			selectRef.current.blur()
			setShouldFocus(false)
		}
	}, [shouldFocus])

	const measureTextWidth = text => {
		const canvas = document.createElement('canvas')
		const context = canvas.getContext('2d')
		context.font = getComputedStyle(document.body).fontSize + ' sans-serif'
		return context.measureText(text).width
	}

	return (
		<div className={styles.SelectWrapper} ref={ref}>
			<label className={styles.Label}>
				{labelText && <span>{labelText}</span>}
				<div
					className={cn(styles.Select, { [styles.Open]: isOpen })}
					onClick={handleToggle}
					role='combobox'
					aria-haspopup='listbox'
					aria-expanded={isOpen}
					aria-controls='options-list'
					tabIndex={0}
					ref={selectRef}
					style={{ width: selectWidth }}
				>
					<div className={styles.SelectedOption}>
						{value
							? options.find(option => option.value === value)?.label
							: placeholder}
					</div>
					<UilAngleDown size='20' color='#333333' />
				</div>
			</label>
			{isOpen && (
				<div
					className={styles.Options}
					id='options-list'
					role='listbox'
					aria-labelledby='select-label'
					ref={optionsRef}
				>
					{options.map(option => (
						<div
							key={option.value}
							className={cn(styles.Option, {
								[styles.Selected]: value === option.value
							})}
							onClick={() => handleOptionClick(option.value)}
							role='option'
							aria-selected={value === option.value}
						>
							{option.label}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default React.memo(Select)
