import {
	UilChartBar,
	UilEdit,
	UilExclamationOctagon,
	UilSync,
	UilTrash,
	UilUserPlus
} from '@iconscout/react-unicons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	CONSTANTS,
	getCompanyStatus,
	getCompanyStatuses,
	getPackageOption,
	getPackageType
} from 'store/slices/constants'
import { PACKAGES, getPackages } from 'store/slices/packages'

import styles from '../../App.module.scss'
import { ROLES } from '../../constants'
import Button from '../../primitives/Button'
import Form from '../../primitives/Form'
import LoaderSpinner from '../../primitives/LoaderSpinner'
import Modal from '../../primitives/Modal'
import Table from '../../primitives/Table'
import { ROUTES_PATHS, ROUTES_TITLES } from '../../router/routes'
import { AUTH } from '../../store/slices/auth'
import {
	CLIENTS,
	createClient,
	getClients,
	refreshClientPackage,
	refreshStats,
	removeClient,
	updateClient
} from '../../store/slices/clients'

const Clients = () => {
	const constants = useSelector(state => state[CONSTANTS])
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const [page, setPage] = useState(Number(query.get('page')) || 1)
	const companyStatus = useSelector(getCompanyStatus)
	const companyStatuses = useSelector(getCompanyStatuses)
	const packageType = useSelector(getPackageType)
	const packageOption = useSelector(getPackageOption)
	const packages = useSelector(state => state[PACKAGES])
	const authUser = useSelector(state => state[AUTH].entity)
	const isAuthUserSuperAdmin = authUser && authUser.role === ROLES.SUPER_ADMIN
	const dispatch = useDispatch()
	const clients = useSelector(state => state[CLIENTS])
	const pageSize = 10
	const navigate = useNavigate()
	const [editingClient, setEditingClient] = useState(null)
	const [statisticsClientId, setStatisticsClientId] = useState(null)
	const [isCreatingOpen, setIsCreatingOpen] = useState(false)

	const openEditingModal = useCallback(
		id => {
			setEditingClient(clients.entities.find(c => c.id === id))
		},
		[clients.entities]
	)
	const closeEditingModal = useCallback(() => setEditingClient(null), [])
	const openCreatingModal = useCallback(() => setIsCreatingOpen(true), [])
	const closeCreatingModal = useCallback(() => setIsCreatingOpen(false), [])
	const openStatisticsModal = useCallback(
		id => {
			setStatisticsClientId(clients.entities.find(c => c.id === id).id)
		},
		[clients.entities]
	)
	const closeStatisticsModal = useCallback(
		() => setStatisticsClientId(null),
		[]
	)

	const findPackage = useCallback(
		id => (id ? packages?.entities?.find(p => p.id === id) : undefined),
		[packages]
	)

	const COLUMNS = [
		{ key: 'name', displayName: 'Название' },
		{
			key: 'status',
			displayName: 'Статус',
			value: item => companyStatus(item.status)
		},
		{
			key: 'package_type',
			displayName: 'Тип пакета',
			value: item => packageType(item.package?.package_type)
		},
		{
			key: 'package_name',
			displayName: 'Название пакета',
			value: item => item.package?.name
		},
		{
			key: 'package_option',
			displayName: 'Опции',
			value: item => packageOption(item.package?.package_option)
		},
		{
			key: 'req_limit',
			displayName: 'Лимит запросов',
			value: item => item.package?.limits
		},
		{
			key: 'requests_available',
			displayName: 'Доступно запросов'
		},
		{
			key: 'warning',
			displayName: '',
			value: item => {
				if (
					(item.package.package_type === 'subscribe' &&
						item.requests_available <= item.limit_alarm) ||
					(['indefinitely', 'subscribe'].includes(item.package.package_type) &&
						item.package_update +
							60 * 60 * 24 * 30 -
							Math.floor(Date.now() / 1000) <
							item.time_alarm * 60 * 60 * 24)
				) {
					return <UilExclamationOctagon color='red' />
				}
			}
		}
	]

	const CREATE_FIELDS = useMemo(
		() => [
			{
				name: 'name',
				labelText: 'Название',
				required: true,
				placeholder: 'ПАО "Газпромбанк"'
			},
			{
				name: 'limit_alarm',
				labelText: 'Заканчивающиеся лимиты',
				required: false,
				placeholder: '1000'
			},
			{
				name: 'time_alarm',
				labelText: 'Заканчивающееся время',
				required: false,
				placeholder: '1000'
			},
			{
				name: 'package_id',
				labelText: 'Пакет',
				required: true,
				type: 'select',
				options: packages?.entities?.map(pack => ({
					value: pack.id,
					label: pack.name
				}))
			}
		],
		[packages]
	)

	const EDIT_FIELDS = useMemo(
		() =>
			CREATE_FIELDS.concat([
				{
					name: 'status',
					labelText: 'Статус',
					type: 'select',
					options: Object.keys(companyStatuses).map(key => ({
						value: key,
						label: companyStatuses[key]
					})),
					required: true,
					initialValue: 'active'
				}
			]),
		[packages, companyStatuses]
	)

	const handleCreateClient = useCallback(client => {
		return dispatch(
			createClient({
				...client,
				status: 'active',
				package: findPackage(client.package_id)
			})
		)
			.unwrap()
			.then(() => closeCreatingModal())
			.then(() => ({}))
			.catch(error => ({ error }))
	}, [])

	const handleUpdateClient = useCallback(
		client => {
			return dispatch(
				updateClient({
					id: editingClient.id,
					...client,
					package: findPackage(client.package_id)
				})
			)
				.unwrap()
				.then(() => {
					dispatch(getClients({ page, pageSize }))
				})
				.catch(error => ({ error }))
		},
		[editingClient]
	)

	const refreshPackage = useCallback(id => {
		return dispatch(refreshClientPackage(id))
			.then(() => ({}))
			.catch(error => ({ error }))
	})

	const handleRemoveClient = useCallback(id => {
		return dispatch(removeClient(id))
			.then(() => ({}))
			.catch(error => ({ error }))
	})

	// const handleRemoveClient = useCallback((id) => {
	//     return dispatch(removeClient(id))
	//         .unwrap()
	//         .then(() => dispatch(getClients({page, pageSize})))  // Обновляем список клиентов
	//         .catch((error) => ({error}));
	// }, [dispatch, page, pageSize]);

	useEffect(() => {
		dispatch(getClients({ page, pageSize }))
		dispatch(getPackages({ page: 1, pageSize: 1000000 }))
	}, [page, dispatch])

	useEffect(() => {
		navigate(`/clients?page=${page}`)
	}, [page])

	const actions = useMemo(
		() => [
			{
				key: 'stats',
				title: 'Статистика',
				icon: <UilChartBar size='20' color='#333333' />,
				handle: openStatisticsModal
			},
			{
				key: 'edit',
				title: 'Редактировать',
				icon: <UilEdit size='20' color='#333333' />,
				handle: openEditingModal
			},
			{
				key: 'refresh',
				title: 'Обновить пакет',
				icon: <UilSync size='20' color='#333333' />,
				handle: refreshPackage
			},
			{
				key: 'remove',
				title: 'Удалить',
				icon: <UilTrash size='20' color='#333333' />,
				handle: handleRemoveClient
			}
		],
		[
			openEditingModal,
			refreshPackage,
			handleRemoveClient,
			clients,
			isAuthUserSuperAdmin
		]
	)

	const editingFields = useMemo(
		() =>
			editingClient
				? EDIT_FIELDS.map(f => ({
						...f,
						initialValue: editingClient[f.name] || f.initialValue
				  }))
				: null,
		[packages, editingClient, companyStatuses]
	)

	const refreshStatsCompany = () => {
		dispatch(refreshStats({ company_id: statisticsClientId }))
	}

	return (
		<div>
			<h2 className={styles.MobileTitle}>
				{ROUTES_TITLES[ROUTES_PATHS.CLIENTS]}
			</h2>
			<Button icon={<UilUserPlus />} onClick={openCreatingModal}>
				Создать клиента
			</Button>
			{constants.loading && clients.loading && !clients.entities && (
				<LoaderSpinner />
			)}
			{clients.total && (
				<Table
					data={clients.entities}
					columns={COLUMNS}
					actions={actions}
					total={clients.total}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
				/>
			)}
			<Modal
				isOpen={isCreatingOpen}
				onClose={closeCreatingModal}
				title='Добавить клиента'
			>
				<Form
					column
					fields={CREATE_FIELDS}
					buttonText='Сохранить'
					buttonIcon={<UilUserPlus />}
					onSubmit={handleCreateClient}
				/>
			</Modal>
			<Modal
				isOpen={editingClient}
				onClose={closeEditingModal}
				title='Изменить клиента'
			>
				<Form
					column
					fields={editingFields}
					buttonText='Сохранить'
					buttonIcon={<UilUserPlus />}
					onSubmit={handleUpdateClient}
				/>
			</Modal>
			<Modal
				isOpen={statisticsClientId}
				onClose={closeStatisticsModal}
				title='Статистика клиента'
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						width: '100%',
						gap: 20
					}}
				>
					<p
						style={{
							width: '100%',
							maxHeight: 400,
							overflow: 'auto'
						}}
						dangerouslySetInnerHTML={{
							__html:
								clients.entities[
									clients.entities.findIndex(
										client => client.id === statisticsClientId
									)
								]?.stats
						}}
					/>

					<Button
						type='submit'
						icon={<UilSync />}
						uppercase
						onClick={refreshStatsCompany}
					>
						Обновить статистику
					</Button>
				</div>
			</Modal>
		</div>
	)
}

export default React.memo(Clients)