import {
	UilCommentInfo,
	UilDocumentInfo,
	UilExternalLinkAlt,
	UilFileDownloadAlt,
	UilRefresh
} from '@iconscout/react-unicons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import styles from '../../App.module.scss'
import { getAccessToken } from '../../api'
import Tabs from '../../components/Tabs'
import { ROLES } from '../../constants'
import FilterComponent, {
	Filter,
	FilterType,
	SelectedFilters
} from '../../primitives/Filter'
import LoaderSpinner from '../../primitives/LoaderSpinner'
import Modal from '../../primitives/Modal'
import Table from '../../primitives/Table'
import { ROUTES_PATHS, ROUTES_TITLES } from '../../router/routes'
import { AppDispatch, IAuthUser, IRootState } from '../../store'
import { AUTH } from '../../store/slices/auth'
import { CLIENTS, getClients } from '../../store/slices/clients'
import {
	CONSTANTS,
	getReportMethod,
	getRequestSource,
	getRequestStatus,
	getRequestStatusDescription,
	getRequestType
} from '../../store/slices/constants'
import {
	ReportsState,
	SEARCH,
	getReports,
	recreateReportPdf,
	restartReport,
	setReportStatus
} from '../../store/slices/search'
import { IReport, IReportRequest } from '../../store/slices/search/types'
import { IUser, USERS, UsersState, getUsers } from '../../store/slices/users'
import formatDate from '../../utils/formatDate'

import wsService from './service.js'

const onlyContext = process.env.REACT_APP_ONLY_CONTEXT

const formatRequestData = (requestData: IReportRequest): string => {
	const propertiesOrder: (keyof IReportRequest)[] = [
		'unstructured',
		'surname',
		'name',
		'patronymic',
		'birth_day',
		'birth_month',
		'birth_year',
		'social_security_number',
		'individual_enterpreneur_registration_code',
		'tax_code',
		'vehicle_number',
		'vehicle_vin',
		'driver_license',
		'driver_license_date',
		'document',
		'email',
		'phone',
		'address_geo',
		'company_tax_id',
		'company_prsn',
		'tax_id',
		'bank_id',
		'ssn',
		'company_name',
		'passport'
	]

	const values: string[] = []
	const birthDateParts: string[] = []

	for (const prop of propertiesOrder) {
		if (requestData[prop] !== null) {
			if (
				prop === 'birth_day' ||
				prop === 'birth_month' ||
				prop === 'birth_year'
			) {
				// Если это день, месяц или год, добавляем в массив для даты
				if (requestData[prop]) {
					birthDateParts.push(requestData[prop]!)
				}
			} else {
				// Для остальных значений просто добавляем в массив
				values.push(requestData[prop]!)
			}
		}

		// После обработки последнего элемента даты (birth_year), добавляем дату в values
		if (prop === 'birth_year' && birthDateParts.length > 0) {
			values.push(birthDateParts.join('.'))
		}
	}

	return values.join(' ')
}

const reportName = (report: IReport) => {
	if (report.request_data.unstructured) {
		return report.request_data.unstructured
	}
	return formatRequestData(report.request_data)
}

const Reports = () => {
	const constants = useSelector<IRootState, any>(state => state[CONSTANTS])
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const [page, setPage] = useState(Number(query.get('page')) || 1)
	const [selectedCompany, setSelectedCompany] = useState(0)
	const [isIsEntityOpen, setIsIsEntityOpen] = useState(false)
	const [activeEntityId, setActiveEntityId] = useState(0)
	const users = useSelector<IRootState, UsersState>(state => state[USERS])
	const companies = useSelector<IRootState, any>(state => state[CLIENTS])
	const userRef = useMemo(
		() =>
			users.entities?.reduce((s: any, user: IUser) => {
				s[user.id] = user
				return s
			}, {}),
		[users.entities]
	)
	const navigate = useNavigate()
	const companiesRef = useMemo(
		() =>
			companies.entities?.reduce((s: any, company: any) => {
				s[company.id] = company
				return s
			}, {}),
		[companies.entities]
	)
	const authUser = useSelector<IRootState, IAuthUser | null>(
		state => state[AUTH].entity
	)
	const openEntityModal = useCallback((id: number) => {
		setActiveEntityId(id)
		setIsIsEntityOpen(true)
	}, [])
	const closeEntityModal = useCallback(() => {
		setActiveEntityId(0)
		setIsIsEntityOpen(false)
	}, [])

	const isAuthUserAnyAdmin =
		authUser &&
		(authUser.role === ROLES.CLIENT_ADMIN ||
			authUser.role === ROLES.SUPER_ADMIN)
	const isAuthUserSuperAdmin = authUser && authUser.role === ROLES.SUPER_ADMIN
	const isAuthUserClientAdmin = authUser && authUser.role === ROLES.CLIENT_ADMIN

	const reports = useSelector<IRootState, ReportsState>(
		state => state[SEARCH].reports
	)
	const requestType = useSelector(getRequestType)
	const reportMethod = useSelector(getReportMethod)
	const requestStatus = useSelector(getRequestStatus)
	const requestStatusDescription = useSelector(getRequestStatusDescription)
	const requestSource = useSelector(getRequestSource)
	const [filter, setFilter] = useState<SelectedFilters | null>(null)
	const pageSize = 10
	const dispatch = useDispatch<AppDispatch>()

	const COLUMNS: any = []
	if (location.pathname.split('/')[2] !== 'lpr-mass') {
		COLUMNS.push({
			key: 'entityName',
			displayName: 'Запрос',
			value: reportName
		})
	}
	COLUMNS.push({
		key: 'date',
		displayName: 'Дата',
		value: (report: IReport) => formatDate(report.created)
	})

	const tabs = useMemo(() => {
		let SHOW_TABS = []

		if (onlyContext === '1') {
			SHOW_TABS = [{ path: 'standard', title: 'Стандартные' }]
		} else {
			SHOW_TABS = [
				{ path: 'factory', title: 'Полные' },
				{ path: 'standard', title: 'Стандартные' },
				{ path: 'lpr-by-inn', title: 'ЛПР' },
				{ path: 'lpr-mass', title: 'ЛПР массовый' }
			]
		}

		return SHOW_TABS
	}, [onlyContext]) // Добавляем `onlyContext` в зависимости для useMemo

	const USER_COLUMN: any = {
		key: 'userFullName',
		displayName: 'Пользователь',
		value: (report: IReport) => {
			if (report.user_id && userRef) {
				const user: any = userRef[report.user_id]
				if (user) return user.full_name
			}
			return report.user_id
		}
	}

	const COMPANY_COLUMN: any = {
		key: 'companyName',
		displayName: 'Компания',
		value: (report: IReport) => {
			if (report.company_id && companiesRef) {
				const company: any = companiesRef[report.company_id]
				if (company) return company.name
			}
			return report.company_id
		}
	}

	const REQUEST_TYPE_COLUMN: any = {
		key: 'request_type',
		displayName: 'Тип запроса',
		value: (report: any) => requestType(report.request_type)
	}

	if (location.pathname.split('/')[2] === 'factory') {
		COLUMNS.push(REQUEST_TYPE_COLUMN)
	}

	const REPORT_METHOD_COLUMN: any = {
		key: 'report_method',
		displayName: 'Метод отчета',
		value: (report: any) => reportMethod(report.report_method)
	}

	if (
		location.pathname.split('/')[2] === 'lpr-by-inn' ||
		location.pathname.split('/')[2] === 'lpr-mass'
	) {
		COLUMNS.push(REPORT_METHOD_COLUMN)
	}

	const STATUS_COLUMN: any = {
		key: 'status',
		displayName: 'Статус',
		value: (report: any) => requestStatus(report.status)
	}

	const SOURCE_COLUMN: any = {
		key: 'request_source',
		displayName: 'Источник запроса',
		value: (report: any) => requestSource(report.request_source)
	}

	const STATUS_DESCRIPTION_COLUMN: any = {
		key: 'status_description',
		displayName: 'Сообщение',
		value: (report: any) => requestStatusDescription(report.status_description)
	}

	const handleRestartReport = (request_id: number) => {
		dispatch(restartReport(request_id))
	}
	const handleRecreateReportPdf = (request_id: number) => {
		dispatch(recreateReportPdf(request_id))
	}

	const findReport = (id: any) => reports.entities.find(rep => rep.id === id)
	const actions = useMemo(
		() => [
			(item: IReport) => {
				const currentTime = Date.now()
				const createdTime = new Date(item.created).getTime()
				const timeElapsed = (currentTime - createdTime) / 1000

				return (item.status == 'fail' ||
					item.status == 'done_without_context') &&
					timeElapsed > 1200
					? {
							key: 'refresh',
							handle(id: any) {
								handleRestartReport(id)
							},
							icon: <UilRefresh />,
							title: 'Перезапустить'
					  }
					: undefined
			},
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'recreate',
							handle(id: any) {
								handleRecreateReportPdf(id)
							},
							icon: <UilDocumentInfo />,
							title: 'Перериосвать отчет'
					  }
					: undefined,
			(item: IReport) =>
				item.status == 'done' &&
				item.report_type !== 'factory' &&
				item.report_type !== 'standard'
					? {
							key: 'json',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_json) {
									window.open(report.report_url_json)
								}
							},
							icon: <UilExternalLinkAlt />,
							title: 'Открыть json'
					  }
					: undefined,
			(item: IReport) =>
				item.status == 'done' &&
				item.report_type !== 'factory' &&
				item.report_type !== 'standard'
					? {
							key: 'xlsx',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_xlsx) {
									window.open(report.report_url_xlsx)
								}
							},
							icon: <UilFileDownloadAlt />,
							title: 'Открыть xlsx'
					  }
					: undefined,
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'link',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_html) {
									window.open(report.report_url_html)
								}
							},
							icon: <UilExternalLinkAlt />,
							title: 'Открыть'
					  }
					: undefined,
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'download',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url) {
									window.open(report.report_url)
								}
							},
							icon: <UilFileDownloadAlt />,
							title: 'Выгрузить'
					  }
					: undefined
		],
		[reports.entities]
	)

	const filters: Filter[] = useMemo(() => {
		if (!users.entities || !authUser) return []
		if (isAuthUserSuperAdmin) {
			return [
				{
					field: 'company',
					label: 'Компания',
					type: FilterType.Select,
					options: companies.entities.map((u: any) => ({
						label: u.name,
						value: String(u.id)
					}))
				},
				{
					field: 'user',
					label: 'Пользователь',
					type: FilterType.Select,
					options: selectedCompany
						? users.entities
								.filter(
									item => item.company_id && item.company_id === selectedCompany
								)
								.map(u => ({
									label: `${u.lastname} ${u.firstname ? u.firstname[0] : ''}. ${
										u.middlename ? u.middlename[0] : ''
									}.`,
									value: String(u.id)
								}))
						: users.entities.map(u => ({
								label: `${u.lastname} ${u.firstname ? u.firstname[0] : ''}. ${
									u.middlename ? u.middlename[0] : ''
								}.`,
								value: String(u.id)
						  }))
				},
				{ field: 'date', label: 'Период', type: FilterType.DateRange }
			]
		} else if (isAuthUserClientAdmin) {
			return [
				{
					field: 'user',
					label: 'Пользователь',
					type: FilterType.Select,
					options: users.entities.map(u => ({
						label: `${u.lastname} ${u.firstname ? u.firstname[0] : ''}. ${
							u.middlename ? u.middlename[0] : ''
						}.`,
						value: String(u.id)
					}))
				},
				{ field: 'date', label: 'Период', type: FilterType.DateRange }
			]
		} else {
			return [{ field: 'date', label: 'Период', type: FilterType.DateRange }]
		}
	}, [users.entities, isAuthUserAnyAdmin, authUser, selectedCompany])

	// const formattedData = useMemo(() => DATA.map((e) => ({ ...e, date: formatDate(e.date) })), []);

	const columns = useMemo(() => {
		if (!authUser) return null

		let result = []
		if (authUser.role === ROLES.SUPER_ADMIN) {
			result.push({
				key: 'id',
				displayName: 'id',
				value: (report: IReport) => (
					<div
						style={{
							display: 'flex',
							gap: 8,
							alignItems: 'center'
						}}
					>
						<Link
							to={`/report?id=${report.id}&type=${report.report_type}`}
							target='_blank'
						>
							{report.id}
						</Link>
						{report.report_type !== 'lpt_mass' && (
							<UilCommentInfo onClick={() => openEntityModal(report.id)} />
						)}
					</div>
				)
			})
		}
		result = [...result, ...COLUMNS]
		if (isAuthUserAnyAdmin) result.push(USER_COLUMN)
		if (location.pathname.split('/')[2] === 'standard') {
			result.push({
				key: 'fail_count',
				displayName: 'Ошибок'
			})
		}
		if (location.pathname.split('/')[2] === 'lpr-mass') {
			result.push({
				key: 'lpr_count',
				displayName: 'Контактов'
			})
		}
		if (authUser.role === ROLES.SUPER_ADMIN) {
			result.push(COMPANY_COLUMN)
		}
		result.push(SOURCE_COLUMN, STATUS_COLUMN, STATUS_DESCRIPTION_COLUMN)
		return result
	}, [isAuthUserAnyAdmin, authUser, users, companies, constants, location])

	useEffect(() => {
		if (authUser?.id && getAccessToken()) {
			wsService.attach(authUser.id, getAccessToken(), (message: any) => {
				if (message.report_id) {
					dispatch(
						setReportStatus({
							report_type: message.report_type,
							id: message.report_id,
							status: message.status,
							status_description: message.text
						})
					)
				}
			})
			wsService.connect()
			// return () => wsService.disconnect()
		}
	}, [authUser])

	useEffect(() => {
		let path = location.pathname.split('/')[2]
		if (onlyContext === '1') {
			path = 'standard'
		}
		dispatch(
			getReports({
				filter,
				page,
				pageSize,
				path: path
			})
		)
	}, [page, filter, location])

	useEffect(() => {
		dispatch(getUsers({ page: 1, pageSize: 1000000 }))
		//@ts-ignore
		dispatch(getClients({ page: 1, pageSize: 1000000 }))
	}, [])

	useEffect(() => {
		navigate(`?page=${page}`)
	}, [page, location.pathname.split('/')[2]])

	useEffect(() => {
		if (!location.pathname.split('/')[2]) {
			if (onlyContext === '1') {
				navigate('/reports/standard')
			} else {
				navigate('/reports/factory')
			}
		}
	}, [location])

	return (
		<div>
			<h2 className={styles.MobileTitle}>
				{ROUTES_TITLES[ROUTES_PATHS.REPORTS.INDEX]}
			</h2>
			<Tabs
				tabs={tabs}
				handleChange={() => {
					setPage(1)
				}}
			/>
			<FilterComponent
				filters={filters}
				applyFilters={setFilter}
				onChange={value => {
					if (value?.company) {
						setSelectedCompany(Number(value.company))
					} else {
						setSelectedCompany(0)
					}
				}}
			/>
			{constants.loading || reports.loading || !filters ? (
				<LoaderSpinner />
			) : (
				<Table
					data={reports.entities}
					columns={columns}
					actions={actions}
					total={reports.total}
					page={page}
					setPage={setPage}
				/>
			)}
			<Modal
				isOpen={isIsEntityOpen}
				onClose={closeEntityModal}
				title='Данные запроса'
			>
				<div>
					{reports &&
						reports.entities.find(report => report.id === activeEntityId) &&
						Object.entries(
							reports.entities.filter(report => report.id === activeEntityId)[0]
								.request_data
						).map(item => (
							<p
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexWrap: 'wrap'
								}}
							>
								<strong>
									{item[0]}
									{' :'}
								</strong>

								<span>{item[1] || '-'}</span>
							</p>
						))}
				</div>
			</Modal>
		</div>
	)
}

export default React.memo(Reports)
