import DateInput from 'primitives/DateInput'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'

import File from '../../../File'
import GroupInput from '../../../GroupInput'
import Input from '../../../Input'
import RangeInput from '../../../RangeInput'
import Select from '../../../Select'

import styles from './index.module.scss'

const GroupField = ({
	className,
	title,
	values,
	onChangeField,
	childrenFields
}) => {
	const withValues = fields =>
		fields.map(field => ({
			...field,
			value: values[field.name],
			onChange: onChangeField(field.name)
		}))

	const resolveInput = field => {
		if (field.type === 'range') {
			return (
				<RangeInput
					labelText={field.labelText}
					value={values[field.name]}
					onChange={onChangeField(field.name)}
					minValue={field.minValue}
					maxValue={field.maxValue}
				/>
			)
		} else if (field.type === 'select') {
			return (
				<div className={styles.Field} key={field.name}>
					<Select
						labelText={field.labelText}
						value={values[field.name]}
						placeholder={field.placeholder}
						options={field.options}
						onChange={onChangeField(field.name)}
					/>
				</div>
			)
		} else if (field.type === 'date') {
			let date = values[field.name]
			if (date && !(date instanceof Date)) {
				date = new Date(date)
			}
			return (
				<div className={styles.Field} key={field.name}>
					<DateInput
						value={date}
						onChange={onChangeField(field.name)}
						labelText={field.labelText}
						placeholder={field.placeholder}
					/>
				</div>
			)
		} else if (field.type === 'file') {
			return (
				<File
					{...field}
					value={values[field.name]}
					onChange={onChangeField(field.name)}
					key={field.name}
				/>
			)
		} else {
			return (
				<Input
					{...field}
					value={values[field.name]}
					onChange={onChangeField(field.name)}
					key={field.name}
				/>
			)
		}
	}

	return (
		<div className={className}>
			{title && <h4 className={styles.Title}>{title}</h4>}
			<div className={styles.Inputs}>
				{childrenFields.map(field =>
					!field.children ? (
						resolveInput(field)
					) : (
						<GroupInput
							{...field}
							labelText={field.groupTitle}
							inputs={withValues(field.children)}
							key={field.name}
						/>
					)
				)}
			</div>
		</div>
	)
}

export default React.memo(GroupField)
