import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance, { authorized } from '../../../api'
import { SelectedFilters } from '../../../primitives/Filter'

import { IReport, SearchState, StructuredReportPayload } from './types'

export const SEARCH = 'search'

const buildURLParams = (params: any) =>
	params
		? Object.keys(params).reduce(
				(s: string, name: string) =>
					s + (s.length ? '&' : '?') + name + '=' + encodeURI(params[name]),
				''
		  )
		: ''

export const getUnstructuredReport = createAsyncThunk(
	`${SEARCH}/getUnstructuredReport`,
	async (unstructured: string) => {
		return await axiosInstance
			.post<string>(
				'/request/check' + buildURLParams({ unstructured }),
				null,
				authorized()
			)
			.then(response => response.data)
	}
)

interface StructuredReportProps {
	structured: StructuredReportPayload
	path: string
}

export const getStructuredReport = createAsyncThunk(
	`${SEARCH}/getStructuredReport`,
	async (props: StructuredReportProps) => {
		let params: any = {}
		const { method_type, ...paramsWithoutMethodType } = props.structured

		const url =
			props.path === 'lpr'
				? '/request_lpr/check'
				: props.path === 'lpr-mass'
				? '/request_mass/lpr/xls' +
				  buildURLParams({ method: props.structured.method })
				: props.path === 'standard'
				? `/request_standard/check/${props.structured.method_type}` +
				  buildURLParams(paramsWithoutMethodType)
				: '/request/check' + buildURLParams(props.structured)

		switch (props.path) {
			case 'lpr': {
				params = props.structured
				break
			}
			case 'lpr-mass': {
				params = props.structured.file
				break
			}
		}

		return await axiosInstance
			.post<string>(url, params, authorized())
			.then(response => response.data)
	}
)

export const restartReport = createAsyncThunk(
	`${SEARCH}/restartReport`,
	async (request_id: number) => {
		try {
			const response = await axiosInstance.put<string>(
				`/request/${request_id}/refresh`,
				null,
				authorized()
			)
			return {
				id: request_id,
				status: 'pending' // Устанавливаем статус в 'pending'
			}
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const recreateReportPdf = createAsyncThunk(
	`${SEARCH}/recreateReport`,
	async (request_id: number) => {
		try {
			const response = await axiosInstance.put<string>(
				`/request/${request_id}/recreate_report`,
				null,
				authorized()
			)
			return {
				id: request_id,
				message: '🎨 Рисуем красивый отчет' // Устанавливаем статус в 'pending'
			}
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

interface ReportsProps {
	page?: number
	pageSize?: number
	filter?: SelectedFilters | null
	path?: string
}

export const getReports = createAsyncThunk(
	`${SEARCH}/getReports`,
	async (props: ReportsProps) => {
		const params: any = {
			page_num: props.page ?? 1,
			per_page: props.pageSize ?? 10,
			order: 'created',
			sort: 'desc'
		}
		const url =
			props.path === 'lpr-by-inn'
				? '/request_lpr/query'
				: props.path === 'lpr-mass'
				? '/request_mass/query'
				: props.path === 'standard'
				? '/request_standard/query'
				: '/request/query'

		switch (props.path) {
			case 'factory': {
				params.filters = {
					...params.filters
				}
				break
			}
			case 'lpr-by-inn': {
				params.filters = {
					...params.filters
				}
				break
			}
		}
		if (props.filter?.user) {
			params.filters = {
				...params.filters,
				user_id: Number(props.filter?.user)
			}
		}
		if (props.filter?.company) {
			params.filters = {
				...params.filters,
				company_id: Number(props.filter?.company)
			}
		}
		if (props.filter?.['date-start']) {
			const start = props.filter?.['date-start'] as string
			params.filters_gte = { created: Math.floor(Date.parse(start) / 1000) }
		}
		if (props.filter?.['date-end']) {
			const end = props.filter?.['date-end'] as string
			params.filters_lte = { created: Math.floor(Date.parse(end) / 1000) }
		}

		return await axiosInstance
			.post<any>(url, params, authorized())
			.then(response => response.data)
	}
)

interface ReportProps {
	id: number
	type: string
}

export const getReportById = createAsyncThunk(
	`${SEARCH}/getReportById`,
	async (props: ReportProps) => {
		const url =
			props.type === 'lpr_by_inn'
				? '/request_lpr/'
				: props.type === 'lpr_mass'
				? '/request_mass/'
				: props.type === 'standard'
				? '/request_standard/'
				: '/request/'

		return await axiosInstance
			.get<any>(`${url}${props.id}`, authorized())
			.then(response => response.data)
	}
)

export interface ReportsState {
	entities: IReport[]
	total: number
	loading: boolean
}

const initialState: SearchState = {
	unstructured: { entity: null, loading: false },
	structured: { entity: null, loading: false },
	reports: {
		entities: [],
		loading: false,
		total: 0
	},
	report: {
		entities: {
			id: 0,
			report_id: '',
			user_id: 0,
			request_type: '',
			request_data: {},
			request_source: '',
			created: 0,
			report_url: '',
			report_url_html: '',
			report_url_json: '',
			report_url_xlsx: ''
		},
		loading: false,
		total: 0
	}
}

interface ReportStatus {
	id: string
	status: string
	status_description?: string
	report_type: string
}

const searchSlice = createSlice({
	name: SEARCH,
	initialState,
	reducers: {
		setReportStatus: (
			state: SearchState,
			action: PayloadAction<ReportStatus>
		) => {
			state.reports.entities = state.reports.entities?.map(report =>
				report.report_id === action.payload.id &&
				report.report_type === action.payload.report_type
					? {
							...report,
							status: action.payload.status,
							status_description: action.payload.status_description
					  }
					: report
			)

			if (
				state.report.entities.report_id === action.payload.id &&
				state.report.entities.report_type === action.payload.report_type
			) {
				state.report.entities = {
					...state.report.entities,
					status: action.payload.status,
					status_description: action.payload.status_description
				}
			}

			if (
				state.structured.entity &&
				state.structured.entity.report_id === action.payload.id &&
				state.structured.entity.report_type === action.payload.report_type
			) {
				state.structured.entity = {
					...state.structured.entity,
					status: action.payload.status,
					status_description: action.payload.status_description
				}
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUnstructuredReport.pending, state => {
				state.unstructured.loading = true
			})
			.addCase(getUnstructuredReport.fulfilled, (state, action) => {
				state.unstructured.entity = action.payload
				state.unstructured.loading = false
			})
			.addCase(getStructuredReport.pending, state => {
				state.structured.loading = true
			})
			.addCase(getStructuredReport.fulfilled, (state, action) => {
				// @ts-ignore
				state.structured.entity = action.payload
				state.structured.loading = false
			})
			.addCase(getReports.pending, state => {
				state.reports.entities = []
				state.reports.total = 0
				state.reports.loading = true
			})
			.addCase(getReports.fulfilled, (state, action) => {
				state.reports.entities = action.payload.result
				state.reports.total = action.payload.count
				state.reports.loading = false
			})
			.addCase(getReportById.pending, state => {
				state.report.loading = true
			})
			.addCase(getReportById.fulfilled, (state, action) => {
				state.report.entities = action.payload
				state.report.loading = false
			})
			.addCase(restartReport.fulfilled, (state, action) => {
				const { id, status } = action.payload
				state.reports.entities = state.reports.entities.map(report =>
					report.id === id ? { ...report, status } : report
				)
			})
			.addCase(recreateReportPdf.fulfilled, (state, action) => {
				const { id, message } = action.payload
				state.reports.entities = state.reports.entities.map(report =>
					report.id === id ? { ...report, message } : report
				)
			})
	}
})

export default searchSlice.reducer
export const { setReportStatus } = searchSlice.actions
