import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from '../App'
import { ROLES } from '../constants'
import { AppDispatch, IAuthUser, IRootState } from '../store'
import { AUTH, IAuthState, getToken } from '../store/slices/auth'

import { authLoader } from './authLoader'
import { HOME_ROUTES, ROUTES_PATHS } from './routes'

const getRoutesProtection = (user: IAuthUser | null, routePath: string) => {
	if (
		[
			ROUTES_PATHS.SEARCH,
			ROUTES_PATHS.STRUCTURED_SEARCH.INDEX,
			ROUTES_PATHS.REPORTS
		].includes(routePath)
	)
		return authLoader(user)
	if (routePath === ROUTES_PATHS.USERS)
		return authLoader(user, [ROLES.CLIENT_ADMIN, ROLES.SUPER_ADMIN])
	if (routePath === ROUTES_PATHS.CLIENTS)
		return authLoader(user, [ROLES.SUPER_ADMIN])
}
const Router = () => {
	const auth = useSelector<IRootState, IAuthState>(state => state[AUTH])
	const dispatch = useDispatch<AppDispatch>()

	useEffect(() => {
		dispatch(getToken())
	}, [dispatch])

	if (auth.tokenLoading) return null

	const protectedRoutes = HOME_ROUTES.map(route => ({
		...route,
		loader: getRoutesProtection(auth.entity, route.path)
	}))
	const router = createBrowserRouter([
		{ path: ROUTES_PATHS.HOME, Component: App, children: protectedRoutes }
	])
	return <RouterProvider router={router} />
}

export default React.memo(Router)
