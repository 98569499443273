import React, { useCallback, useContext } from 'react'

import Form from '../../../../primitives/Form'
import { StructuredReportNames } from '../../../../store/slices/search/types'
import { StructuredSearchContext } from '../../index'

export type FormValues<T extends string | number | symbol> = {
	[K in T]: boolean | string
}

const FIELDS = [
	{
		name: 'installation-data',
		groupTitle: 'Установочные данные',
		children: [
			{
				name: 'fio',
				groupTitle: 'ФИО',
				children: [
					{ name: 'surname', placeholder: 'Фамилия' },
					{ name: 'name', placeholder: 'Имя' },
					{ name: 'patronymic', placeholder: 'Отчество' }
				]
			},
			{
				name: 'birth-date',
				groupTitle: 'Дата рождения',
				children: [
					{ name: 'birth_day', placeholder: 'День' },
					{ name: 'birth_month', placeholder: 'Месяц' },
					{ name: 'birth_year', placeholder: 'Год' }
				]
			}
			// { name: "age_range", labelText: "Диапазон возраста", type: "range", initialValue: [18, 72]},
			// {name: 'region', labelText: 'Ограничить регион поиска', placeholder: 'Регион поиска'},
		]
	},
	{
		name: 'codes-documents',
		groupTitle: 'Коды и документы',
		children: [
			{
				name: 'codes',
				groupTitle: 'Идентифицирующие коды',
				children: [
					{ name: 'tax_code', placeholder: 'ИНН' },
					{ name: 'social_security_number', placeholder: 'СНИЛС' },
					{
						name: 'individual_enterpreneur_registration_code',
						placeholder: 'ОГРНИП'
					}
				]
			},
			{
				name: 'documents',
				groupTitle: 'Документы',
				children: [
					{ name: 'document', placeholder: 'Серия и номер паспорта' },
					{
						name: 'driver-license',
						placeholder: 'Серия и номер водительского удостоверения'
					}
				]
			}
		]
	},
	{
		name: 'contacts',
		groupTitle: 'Контакты',
		children: [
			{ name: 'phone', placeholder: 'Номер телефона' },
			{ name: 'email', placeholder: 'Электронная почта' },
			{ name: 'address_geo', placeholder: 'Адрес' }
		]
	}
]

const VALIDATE_COMBINATIONS = [
	[{ name: 'tax_code', errorLabel: 'ИНН' }],
	[{ name: 'social_security_number', errorLabel: 'СНИЛС' }],
	[{ name: 'individual_enterpreneur_registration_code', errorLabel: 'ОГРНИП' }],
	// [{name: 'surname', errorLabel: 'Фамилия'}, {name: 'name', errorLabel: 'Имя'}],
	// [{name: 'surname', errorLabel: 'Фамилия'}, {name: 'birth_year', errorLabel: 'Год рождения'}],
	[
		{ name: 'surname', errorLabel: 'Фамилия' },
		{ name: 'name', errorLabel: 'Имя' },
		{ name: 'patronymic', errorLabel: 'Отчество' },
		{ name: 'birth_day', errorLabel: 'День рождения' },
		{ name: 'birth_month', errorLabel: 'Месяц рождения' },
		{ name: 'birth_year', errorLabel: 'Год рождения' }
	],
	[{ name: 'phone', errorLabel: 'Номер телефона' }],
	[{ name: 'email', errorLabel: 'Электронная почта' }],
	[{ name: 'address_geo', errorLabel: 'Адрес' }],
	[{ name: 'document', errorLabel: 'Серия и номер паспорта' }],
	[
		{
			name: 'driver-license',
			errorLabel: 'Серия и номер водительского удостоверения'
		}
	]
] as never[]

const Individual = () => {
	const { handleStructuredReport } = useContext(StructuredSearchContext)

	const handleSubmit = useCallback(
		(values: FormValues<StructuredReportNames>) => {
			return handleStructuredReport(values)
		},
		[handleStructuredReport]
	)

	return (
		<div>
			<Form
				fields={FIELDS}
				onSubmit={handleSubmit}
				buttonText='Искать'
				headerText={undefined}
				onChange={undefined}
				buttonIcon={undefined}
				column={undefined}
				className={undefined}
				validateCombinations={VALIDATE_COMBINATIONS}
			/>
		</div>
	)
}

export default React.memo(Individual)
