import cn from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'

interface ITab {
	path: string
	title: string
}

interface IProps {
	handleChange?: () => void
	tabs: ITab[]
}

const Tabs: React.FC<IProps> = ({ tabs, handleChange }) => {
	return (
		<div className={styles.Tabs}>
			{tabs.map(tab => (
				<NavLink
					to={tab.path}
					onClick={() => handleChange?.()}
					className={({ isActive }) =>
						isActive ? cn(styles.Tab, styles.ActiveTab) : styles.Tab
					}
					key={tab.path}
				>
					{tab.title}
				</NavLink>
			))}
		</div>
	)
}

export default React.memo(Tabs)
