import { UilKeySkeleton } from '@iconscout/react-unicons'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Form from '../../primitives/Form'
import { ROUTES_PATHS } from '../../router/routes'
import { IAuthUser, IRootState } from '../../store'
import { AUTH } from '../../store/slices/auth'
import styles from '../Auth/index.module.scss'

const ForgotPassword = () => {
	const user = useSelector<IRootState, IAuthUser | null>(
		state => state[AUTH].entity
	)
	const navigate = useNavigate()

	const onSubmit = useCallback(async (values: { password: string }) => {
		console.log(values)
	}, [])

	const fields = useMemo(
		() => [
			{
				name: 'email',
				type: 'email',
				labelText: 'Email',
				placeholder: 'example@ifce.ru',
				required: true
			}
		],
		[]
	)

	useEffect(() => {
		if (user) navigate(ROUTES_PATHS.SEARCH)
	}, [user])

	return (
		<Form
			className={styles.Form}
			fields={fields}
			onChange={undefined}
			onSubmit={onSubmit}
			headerText='Восстановление доступа'
			buttonText='Отправить'
			buttonIcon={<UilKeySkeleton />}
			column
		/>
	)
}

export default React.memo(ForgotPassword)
