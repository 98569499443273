import { handleCheckAccessToken } from '../../api'

const WSService = {
	connected: false,
	reconnect: true,
	userId: null,
	accessToken: null,
	onMessage: null,
	attach(userId, accessToken, onMessage) {
		this.userId = userId
		this.accessToken = accessToken
		this.onMessage = onMessage
	},
	connect() {
		if (this.connected) {
			console.warn('WebSocket is already connected.')
			return
		}

		if (!process.env.REACT_APP_WS_URL) {
			console.error('WS path is not specified')
			return
		}
		if (!this.userId || !this.accessToken) {
			if (this.reconnect) {
				setTimeout(this.connect, 500)
			}
			console.log(this.userId, this.accessToken)
			console.error(
				'User id & token are required',
				this.userId,
				this.accessToken
			)

			return
		}

		const url = `${process.env.REACT_APP_WS_URL}${this.userId}`
		this.connection = new WebSocket(url)
		console.info('CONNECT WS to ', url)
		this.connected = true
		this.connection.addEventListener('open', () => {
			if (this.connection.readyState) {
				console.info('WS CONNECTED')
				console.info(
					'SEND',
					JSON.stringify({
						token: this.accessToken
					})
				)
				this.connection.send(
					JSON.stringify({
						token: this.accessToken
					})
				)
			}
		})
		this.connection.addEventListener('message', message => {
			if (typeof this.onMessage == 'function') {
				try {
					const json = JSON.parse(message.data)

					if (json?.status === 401) {
						handleCheckAccessToken()
							.then(token => {
								this.connected = false
								console.log('old token: ', this.accessToken)
								console.log('new token: ', token)
								this.accessToken = token
								this.connect()
							})
							.catch(err => {
								console.error('Error refreshing token:', err)
							})
					}

					console.info(json)
					this.onMessage(json)
				} catch (err) {
					console.error(message)
					console.error(err)
				}
			} else {
				console.info(message)
			}
		})
		this.connection.addEventListener('close', () => {
			console.info('WS DISCONNECTED')
			this.connected = false
			if (this.reconnect) {
				setTimeout(this.connect, 500)
			}
		})
		this.connection.addEventListener('error', error => {
			console.error(error)
		})
	},
	disconnect() {
		console.log('disconnect')
		this.connected = false
		this.connection = false
	}
}

export default WSService
