import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Arrow } from '../../images/header-arrow-gray.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { ReactComponent as SmallLogo } from '../../images/small-logo.svg'
import { ROUTES_PATHS } from '../../router/routes'
import Breadcrumbs from '../Breadcrumbs'

import UserNav from './components/UserNav'
import styles from './index.module.scss'

const Header = ({ user, handleLogout }) => {
	return (
		<header className={styles.Header}>
			{window.innerWidth > 500 && (
				<>
					<Breadcrumbs />
					<Link className={styles.Logo} to={ROUTES_PATHS.HOME}>
						<Logo />
					</Link>
				</>
			)}
			<div className={styles.RightSide}>
				{user && <UserNav user={user} handleLogout={handleLogout} />}
				<div
					className={cn({
						[styles.BorderedContainer]: window.innerWidth <= 500
					})}
				>
					{window.innerWidth <= 500 && (
						<Link to={ROUTES_PATHS.HOME}>
							<SmallLogo />
						</Link>
					)}
					<Arrow />
				</div>
			</div>
		</header>
	)
}

export default React.memo(Header)
