import {
	UilDocumentInfo,
	UilExternalLinkAlt,
	UilFileDownloadAlt
} from '@iconscout/react-unicons'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAccessToken } from '../../api'
import LoaderSpinner from '../../primitives/LoaderSpinner'
// Assume you have this action
import { AppDispatch, IAuthUser, IRootState } from '../../store'
import { AUTH } from '../../store/slices/auth'
import { CLIENTS, getClients } from '../../store/slices/clients'
import {
	getRequestSource,
	getRequestStatus,
	getRequestStatusDescription,
	getRequestType
} from '../../store/slices/constants'
import {
	SEARCH,
	getReportById,
	recreateReportPdf,
	setReportStatus
} from '../../store/slices/search'
import { IUser, USERS, UsersState, getUsers } from '../../store/slices/users'
import formatDate from '../../utils/formatDate'
import wsService from '../Reports/service'

import styles from './index.module.scss'

const ReportDetails = () => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const reportId = query.get('id')
	const reportType = query.get('type')
	const requestType = useSelector(getRequestType)
	const dispatch = useDispatch<AppDispatch>()
	const authUser = useSelector<IRootState, IAuthUser | null>(
		state => state[AUTH].entity
	)
	const requestSource = useSelector(getRequestSource)
	const requestStatusDescription = useSelector(getRequestStatusDescription)
	const requestStatus = useSelector(getRequestStatus)
	const companies = useSelector<IRootState, any>(state => state[CLIENTS])
	const users = useSelector<IRootState, UsersState>(state => state[USERS])

	const handleRecreateReportPdf = (request_id: number) => {
		dispatch(recreateReportPdf(request_id))
	}

	const userRef = useMemo(
		() =>
			users.entities?.reduce((s: any, user: IUser) => {
				s[user.id] = user
				return s
			}, {}),
		[users.entities]
	)
	const companiesRef = useMemo(
		() =>
			companies.entities?.reduce((s: any, company: any) => {
				s[company.id] = company
				return s
			}, {}),
		[companies.entities]
	)
	const reportLoading = useSelector(
		(state: IRootState) => state[SEARCH].report.loading
	)
	const report = useSelector(
		(state: IRootState) => state[SEARCH].report.entities
	)

	useEffect(() => {
		if (reportId && reportType) {
			dispatch(
				getReportById({ id: Number(reportId), type: reportType as string })
			)
		} else {
			// navigate(-1)
		}
	}, [reportId, reportType, dispatch])

	useEffect(() => {
		//@ts-ignore
		dispatch(getUsers({ page: 1, pageSize: 1000000 }))
		//@ts-ignore
		dispatch(getClients({ page: 1, pageSize: 1000000 }))
	}, [])

	useEffect(() => {
		if (authUser) {
			wsService.attach(authUser.id, getAccessToken(), (message: any) => {
				if (message.report_id) {
					dispatch(
						setReportStatus({
							report_type: message.report_type,
							id: message.report_id,
							status: message.status,
							status_description: message.text
						})
					)
				}
			})
			wsService.connect()
			return () => wsService.disconnect()
		}
	}, [authUser])

	if (reportLoading) {
		return <LoaderSpinner />
	}

	if (!report) {
		return <div>Report not found</div>
	}

	return (
		<div className={styles.Report}>
			<h2>Детали отчета</h2>
			<div className={styles.Action}>
				<button
					className={styles.EditButton}
					title='Перериосвать отчет'
					onClick={() => handleRecreateReportPdf(report.id)}
				>
					<UilDocumentInfo />
				</button>
				<button
					className={styles.EditButton}
					title='Открыть'
					onClick={() => window.open(report.report_url_html)}
				>
					<UilExternalLinkAlt />
				</button>
				<button
					className={styles.EditButton}
					title='Выгрузить'
					onClick={() => window.open(report.report_url)}
				>
					<UilFileDownloadAlt />
				</button>
			</div>
			<div className={styles.List}>
				<div className={styles.Row}>
					<p>
						<strong>ID:</strong> {report.id}
					</p>
					<p>
						<strong>Тип запроса:</strong> {requestType(report.request_type)}
					</p>
					<p>
						<strong>Дата:</strong> {formatDate(report.created)}
					</p>
					<p>
						<strong>Пользователь:</strong>{' '}
						{userRef[report.user_id]?.full_name || '-'}
					</p>
					<p>
						<strong>Компания: </strong>
						{report.company_id ? companiesRef[report.company_id]?.name : '-'}
					</p>
					<p>
						<strong>Источник запроса: </strong>
						{requestSource(report.request_source)}
					</p>
					<p>
						<strong>Статус: </strong>
						{report.status ? requestStatus(report.status) : '-'}
					</p>
					<p>
						<strong>Сообщение: </strong>
						{report.status_description
							? requestStatusDescription(report.status_description)
							: '-'}
					</p>
				</div>
				{reportType !== 'lpr_mass' && (
					<div className={styles.Row}>
						<p>
							<strong>Запрос: </strong>
						</p>
						<p>
							{Object.entries(report.request_data).map(item => (
								<p
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap'
									}}
								>
									<strong>
										{item[0]}
										{' :'}
									</strong>

									<span>{item[1] || '-'}</span>
								</p>
							))}
						</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default ReportDetails
