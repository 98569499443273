import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance, { authorized } from '../../api'

export const PACKAGES = 'packages'
export const getPackages = createAsyncThunk(
	`${PACKAGES}/getPackages`,
	async params => {
		return await new Promise((resolve, reject) => {
			axiosInstance
				.post(
					'package/query',
					{
						page_num: params.page,
						per_page: params.pageSize
					},
					authorized()
				)
				.then(res => {
					resolve({
						items: res.data.result,
						total: res.data.count
					})
				})
				.catch(reject)
		})
	}
)

export const createPackage = createAsyncThunk(
	`${PACKAGES}/createPackage`,
	async clientData => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.post('package/create', clientData, authorized())
					.then(res => resolve(res.data))
					.catch(reject)
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const updatePackage = createAsyncThunk(
	`${PACKAGES}/updatePackage`,
	async clientData => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.put(`package/${clientData.id}`, clientData, authorized())
					.then(res => resolve(res.data))
					.catch(reject)
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const deletePackage = createAsyncThunk(
	`${PACKAGES}/deletePackage`,
	async id => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.delete(`package/${id}`, authorized())
					.then(resolve)
					.catch(reject)
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

const packagesSlice = createSlice({
	name: PACKAGES,
	initialState: {
		entities: null,
		total: 0,
		loading: false,
		creating: { loading: false },
		updating: { loading: false }
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getPackages.pending, state => {
				state.loading = true
			})
			.addCase(getPackages.fulfilled, (state, action) => {
				if (action.payload) {
					state.entities = action.payload.items
					state.total = action.payload.total
					state.loading = false
				}
			})
			.addCase(createPackage.pending, state => {
				state.creating.loading = true
			})
			.addCase(createPackage.fulfilled, (state, action) => {
				state.creating.loading = false
				state.entities.push(action.payload)
			})
			.addCase(updatePackage.pending, state => {
				state.updating.loading = true
			})
			.addCase(updatePackage.fulfilled, (state, action) => {
				state.updating.loading = false

				const updatedPackage = action.payload
				const index = state.entities.findIndex(
					client => client.id === updatedPackage.id
				)
				if (index !== -1) {
					state.entities[index] = updatedPackage
				}
			})
			.addCase(deletePackage.fulfilled, (state, action) => {
				const id = action.meta.arg
				const index = state.entities.findIndex(pack => pack.id === id)
				if (index !== -1) {
					state.entities.splice(index, 1)
				}
			})
	}
})

export default packagesSlice.reducer
