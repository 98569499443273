import { UilEllipsisH } from '@iconscout/react-unicons'
import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

const Button = ({
	to = '',
	children,
	icon = '',
	className = '',
	variant = 'primary',
	uppercase = false,
	loading = false,
	...props
}) => {
	const content = icon ? (
		<>
			{children} {icon}
		</>
	) : (
		children
	)
	const classesCombinations = cn(
		styles.Button,
		styles[variant],
		{ [styles.uppercase]: uppercase },
		className
	)

	if (to)
		return (
			<Link {...props} className={classesCombinations} to={to}>
				{content}
			</Link>
		)

	return (
		<button {...props} className={classesCombinations}>
			{loading ? (
				<>
					{children}
					<div className={styles.Loader}>
						<UilEllipsisH color='#fff' className={styles.Spinner} />
					</div>
				</>
			) : (
				content
			)}
		</button>
	)
}

export default React.memo(Button)
