import {
	UilDocumentInfo,
	UilExternalLinkAlt,
	UilFileDownloadAlt,
	UilRefresh
} from '@iconscout/react-unicons'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAccessToken } from '../../api'
import { ROLES } from '../../constants'
import LoaderSpinner from '../../primitives/LoaderSpinner'
import Table from '../../primitives/Table'
import { AppDispatch, IAuthUser, IRootState } from '../../store'
import { CLIENTS, getClients } from '../../store/slices/clients'
import {
	getReportMethod,
	getRequestSource,
	getRequestStatus,
	getRequestStatusDescription,
	getRequestType
} from '../../store/slices/constants'
import {
	ReportsState,
	SEARCH,
	getReports,
	recreateReportPdf,
	restartReport,
	setReportStatus
} from '../../store/slices/search'
import { IReport, IReportRequest } from '../../store/slices/search/types'
import { IUser, USERS, UsersState, getUsers } from '../../store/slices/users'
import formatDate from '../../utils/formatDate'

import wsService from './service'

const formatRequestData = (requestData: IReportRequest): string => {
	const propertiesOrder: (keyof IReportRequest)[] = [
		'unstructured',
		'surname',
		'name',
		'patronymic',
		'birth_day',
		'birth_month',
		'birth_year',
		'social_security_number',
		'individual_enterpreneur_registration_code',
		'tax_code',
		'vehicle_number',
		'vehicle_vin',
		'driver_license',
		'driver_license_date',
		'document',
		'email',
		'phone',
		'address_geo',
		'company_tax_id',
		'company_prsn',
		'tax_id',
		'bank_id',
		'ssn',
		'company_name',
		'passport'
	]

	const values: string[] = []
	const birthDateParts: string[] = []

	for (const prop of propertiesOrder) {
		if (requestData[prop] !== null) {
			if (
				prop === 'birth_day' ||
				prop === 'birth_month' ||
				prop === 'birth_year'
			) {
				// Если это день, месяц или год, добавляем в массив для даты
				birthDateParts.push(requestData[prop]!)
			} else {
				// Для остальных значений просто добавляем в массив
				values.push(requestData[prop]!)
			}
		}

		// После обработки последнего элемента даты (birth_year), добавляем дату в values
		if (prop === 'birth_year' && birthDateParts.length > 0) {
			values.push(birthDateParts.join('.'))
		}
	}

	return values.join(' ')
}

const reportName = (report: IReport) => {
	if (report.request_data.unstructured) {
		return report.request_data.unstructured
	}
	return formatRequestData(report.request_data)
}

interface IProps {
	page: number
	setPage: (page: number) => void
	authUser: IAuthUser | null
	structuredReport?: boolean
}

const SearchTable: FC<IProps> = ({
	page,
	setPage,
	authUser,
	structuredReport
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const reports = useSelector<IRootState, ReportsState>(
		state => state[SEARCH].reports
	)
	const location = useLocation()
	const structuredReports = useSelector<IRootState, any>(
		state => state[SEARCH].structured.entity
	)
	const users = useSelector<IRootState, UsersState>(state => state[USERS])
	const companies = useSelector<IRootState, any>(state => state[CLIENTS])
	const requestType = useSelector(getRequestType)
	const reportMethod = useSelector(getReportMethod)
	const requestStatus = useSelector(getRequestStatus)
	const requestStatusDescription = useSelector(getRequestStatusDescription)
	const requestSource = useSelector(getRequestSource)
	const pageSize = 10

	const userRef = useMemo(
		() =>
			users.entities?.reduce((s: any, user: IUser) => {
				s[user.id] = user
				return s
			}, {}),
		[users.entities]
	)
	const companiesRef = useMemo(
		() =>
			companies.entities?.reduce((s: any, company: any) => {
				s[company.id] = company
				return s
			}, {}),
		[companies.entities]
	)

	const isAuthUserAnyAdmin =
		authUser &&
		(authUser.role === ROLES.CLIENT_ADMIN ||
			authUser.role === ROLES.SUPER_ADMIN)

	const COLUMNS: any = []
	if (location.pathname.split('/')[2] !== 'lpr-mass') {
		COLUMNS.push({
			key: 'entityName',
			displayName: 'Запрос',
			value: reportName
		})
	}
	COLUMNS.push({
		key: 'date',
		displayName: 'Дата',
		value: (report: IReport) => formatDate(report.created)
	})

	const USER_COLUMN: any = {
		key: 'userFullName',
		displayName: 'Пользователь',
		value: (report: IReport) => {
			if (report.user_id && userRef) {
				const user: any = userRef[report.user_id]
				if (user) return user.full_name
			}
			return report.user_id
		}
	}

	const COMPANY_COLUMN: any = {
		key: 'companyName',
		displayName: 'Компания',
		value: (report: IReport) => {
			if (report.company_id && companiesRef) {
				const company: any = companiesRef[report.company_id]
				if (company) return company.name
			}
			return report.company_id
		}
	}

	const REQUEST_TYPE_COLUMN: any = {
		key: 'request_type',
		displayName: 'Тип запроса',
		value: (report: any) => requestType(report.request_type)
	}

	if (location.pathname.split('/')[2] === 'factory') {
		COLUMNS.push(REQUEST_TYPE_COLUMN)
	}

	const REPORT_METHOD_COLUMN: any = {
		key: 'report_method',
		displayName: 'Метод отчета',
		value: (report: any) => reportMethod(report.report_method)
	}

	if (
		location.pathname.split('/')[2] === 'lpr-by-inn' ||
		location.pathname.split('/')[2] === 'lpr-mass'
	) {
		COLUMNS.push(REPORT_METHOD_COLUMN)
	}

	const STATUS_COLUMN: any = {
		key: 'status',
		displayName: 'Статус',
		value: (report: any) => requestStatus(report.status)
	}

	const SOURCE_COLUMN: any = {
		key: 'request_source',
		displayName: 'Источник запроса',
		value: (report: any) => requestSource(report.request_source)
	}

	const STATUS_DESCRIPTION_COLUMN: any = {
		key: 'status_description',
		displayName: 'Сообщение',
		value: (report: any) => requestStatusDescription(report.status_description)
	}

	const handleRestartReport = (request_id: number) => {
		dispatch(restartReport(request_id))
	}
	const handleRecreateReportPdf = (request_id: number) => {
		dispatch(recreateReportPdf(request_id))
	}

	const findReport = (id: any) => reports.entities.find(rep => rep.id === id)
	const actions = useMemo(
		() => [
			(item: IReport) => {
				const currentTime = Date.now()
				const createdTime = new Date(item.created).getTime()
				const timeElapsed = (currentTime - createdTime) / 1000

				return (item.status == 'fail' ||
					item.status == 'done_without_context') &&
					timeElapsed > 1200
					? {
							key: 'refresh',
							handle(id: any) {
								handleRestartReport(id)
							},
							icon: <UilRefresh />,
							title: 'Перезапустить'
					  }
					: undefined
			},
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'recreate',
							handle(id: any) {
								handleRecreateReportPdf(id)
							},
							icon: <UilDocumentInfo />,
							title: 'Перериосвать отчет'
					  }
					: undefined,
			(item: IReport) =>
				item.status == 'done' &&
				item.report_type !== 'factory' &&
				item.report_type !== 'standard'
					? {
							key: 'json',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_json) {
									window.open(report.report_url_json)
								}
							},
							icon: <UilExternalLinkAlt />,
							title: 'Открыть json'
					  }
					: undefined,
			(item: IReport) =>
				item.status == 'done' &&
				item.report_type !== 'factory' &&
				item.report_type !== 'standard'
					? {
							key: 'xlsx',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_xlsx) {
									window.open(report.report_url_xlsx)
								}
							},
							icon: <UilFileDownloadAlt />,
							title: 'Открыть xlsx'
					  }
					: undefined,
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'link',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url_html) {
									window.open(report.report_url_html)
								}
							},
							icon: <UilExternalLinkAlt />,
							title: 'Открыть'
					  }
					: undefined,
			(item: IReport) =>
				(item.status == 'done' || item.status == 'done_without_context') &&
				(item.report_type === 'factory' || item.report_type === 'standard')
					? {
							key: 'download',
							handle(id: any) {
								const report = findReport(id)
								if (report?.report_url) {
									window.open(report.report_url)
								}
							},
							icon: <UilFileDownloadAlt />,
							title: 'Выгрузить'
					  }
					: undefined
		],
		[reports.entities]
	)

	const columns = useMemo(() => {
		if (!authUser) return null

		let result = []
		if (authUser.role === ROLES.SUPER_ADMIN) {
			result.push({
				key: 'id',
				displayName: 'id',
				value: (report: IReport) => report.id
			})
		}
		result = [...result, ...COLUMNS]
		if (isAuthUserAnyAdmin) result.push(USER_COLUMN)
		if (authUser.role === ROLES.SUPER_ADMIN) {
			result.push(COMPANY_COLUMN)
		}
		if (structuredReports && location.pathname.split('/')[2] === 'standard') {
			result.push({
				key: 'fail_count',
				displayName: 'Ошибок'
			})
		}
		if (structuredReports && location.pathname.split('/')[2] === 'lpr-mass') {
			result.push({
				key: 'lpr_count',
				displayName: 'Контактов'
			})
		}
		result.push(SOURCE_COLUMN, STATUS_COLUMN, STATUS_DESCRIPTION_COLUMN)
		return result
	}, [isAuthUserAnyAdmin, authUser, users, companies, location])

	useEffect(() => {
		if (authUser?.id && getAccessToken()) {
			wsService.attach(authUser.id, getAccessToken(), (message: any) => {
				if (message.report_id) {
					dispatch(
						setReportStatus({
							report_type: message.report_type,
							id: message.report_id,
							status: message.status,
							status_description: message.text
						})
					)
				}
			})
			wsService.connect()
			return () => wsService.disconnect()
		}
	}, [authUser])

	useEffect(() => {
		dispatch(getReports({ page, pageSize }))
	}, [page])

	useEffect(() => {
		dispatch(getUsers({ page: 1, pageSize: 1000000 }))
		//@ts-ignore
		dispatch(getClients({ page: 1, pageSize: 1000000 }))
	}, [])

	return (
		<div>
			{reports.loading ? (
				<LoaderSpinner />
			) : (
				<Table
					data={structuredReport ? [structuredReports] : reports.entities}
					columns={columns}
					actions={actions}
					total={structuredReport ? 1 : reports.total}
					page={page}
					setPage={setPage}
				/>
			)}
		</div>
	)
}

export default React.memo(SearchTable)
