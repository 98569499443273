import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance, { authorized } from '../../api'

export const CLIENTS = 'clients'
export const getClients = createAsyncThunk(`${CLIENTS}/getClients`, params => {
	return new Promise((resolve, reject) => {
		axiosInstance
			.post(
				'company/query',
				{
					page_num: params.page,
					per_page: params.pageSize
				},
				authorized()
			)
			.then(res => {
				resolve({
					items: res.data.result,
					total: res.data.count
				})
			})
			.catch(reject)
	})
})

export const refreshStats = createAsyncThunk(
	`${CLIENTS}/refreshStats`,
	params => {
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(`company/${params.company_id}/stats`, authorized())
				.then(res => {
					resolve(res.data)
				})
				.catch(reject)
		})
	}
)

export const createClient = createAsyncThunk(
	`${CLIENTS}/createClient`,
	async clientData => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.post(
						'company/create',
						{
							name: clientData.name,
							limit_alarm: clientData.limit_alarm,
							time_alarm: clientData.time_alarm,
							package_id: clientData.package_id
						},
						authorized()
					)
					.then(res => resolve(res.data))
					.catch(reject)
				// return await new Promise((resolve, reject) => {
				// setTimeout(() => {
				//     if (clientData.name === 'ПАО "Газпромбанк"') reject();
				//     resolve(clientData);
				// }, 1000);
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const updateClient = createAsyncThunk(
	`${CLIENTS}/updateClient`,
	async clientData => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.put(
						`company/${clientData.id}`,
						{
							name: clientData.name,
							package_id: clientData.package_id,
							limit_alarm: clientData.limit_alarm,
							time_alarm: clientData.time_alarm,
							status: clientData.status
						},
						authorized()
					)
					.then(res => resolve(res.data))
					.catch(reject)
				// setTimeout(() => {
				//     if (clientData.name === 'ПАО "Газпромбанк"') reject();
				//     resolve(clientData);
				// }, 1000);
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const removeClient = createAsyncThunk(
	`${CLIENTS}/removeClient`,
	async id => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.delete(`company/${id}`, authorized())
					.then(res => resolve(res.data))
					.catch(reject)
				// setTimeout(() => {
				//     if (clientData.name === 'ПАО "Газпромбанк"') reject();
				//     resolve(clientData);
				// }, 1000);
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

export const refreshClientPackage = createAsyncThunk(
	`${CLIENTS}/refreshClientPackage`,
	async id => {
		try {
			return await new Promise((resolve, reject) => {
				axiosInstance
					.put(`company/${id}/refresh_package`, {}, authorized())
					.then(res => resolve(res.data))
					.catch(reject)
			})
		} catch (error) {
			throw new Error('Ошибка сервера!')
		}
	}
)

const clientsSlice = createSlice({
	name: CLIENTS,
	initialState: {
		entities: [],
		total: 0,
		loading: false,
		creating: { loading: false },
		updating: { loading: false }
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getClients.pending, state => {
				state.loading = true
			})
			.addCase(getClients.fulfilled, (state, action) => {
				if (action.payload) {
					state.entities = action.payload.items
					state.total = action.payload.total
					state.loading = false
				}
			})
			.addCase(refreshStats.fulfilled, (state, action) => {
				state.loading = true
				const item = state.entities.find(
					item => item.id === action.meta.arg.company_id
				)
				const index = state.entities.findIndex(
					client => client.id === action.meta.arg.company_id
				)
				state.entities[index] = {
					...item,
					stats: action.payload
				}
				state.loading = false
			})
			.addCase(createClient.pending, state => {
				state.creating.loading = true
			})
			.addCase(createClient.fulfilled, (state, action) => {
				if (state.entities) {
					state.entities.push(action.payload)
				} else {
					state.entities = [action.payload]
				}
				state.total += 1 // Не забудьте увеличить общее количество клиентов
			})
			.addCase(updateClient.pending, state => {
				state.updating.loading = true
			})
			.addCase(updateClient.fulfilled, (state, action) => {
				state.updating.loading = false

				const updatedClient = action.payload
				const index = state.entities.findIndex(
					client => client.id === updatedClient.id
				)
				if (index !== -1) {
					state.entities[index] = updatedClient
				}
			})
			.addCase(removeClient.fulfilled, (state, action) => {
				const clientIdToRemove = action.meta.arg
				state.entities = state.entities.filter(
					client => client.id !== clientIdToRemove
				)
				state.total -= 1
			})
			.addCase(refreshClientPackage.pending, state => {
				state.updating.loading = true
			})
			.addCase(refreshClientPackage.fulfilled, (state, action) => {
				state.updating.loading = false

				const updatedClient = action.payload
				const index = state.entities.findIndex(
					client => client.id === updatedClient.id
				)
				if (index !== -1) {
					state.entities[index] = updatedClient
				}
			})
	}
})

export default clientsSlice.reducer
