import axios, {
	AxiosError,
	AxiosInstance,
	AxiosResponse,
	InternalAxiosRequestConfig
} from 'axios'
import { redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { logoutUser, refreshAccessToken } from './store/slices/auth'
import isTokenExpiringSoon from './utils/checkToken'

// import store from "./store";

const baseURL = process.env.REACT_APP_API_URL

let accessToken = '123'

const getAccessToken = () => accessToken

const axiosInstance: AxiosInstance = axios.create({
	baseURL
	// headers: { 'X-Authorization': accessToken }
})

// Функция для обработки ошибок
const handleError = (error: AxiosError) => {
	let message = 'Произошла ошибка на сервере'

	// Проверка на наличие поля error с description
	if (error.response && error.response.data) {
		const data = error.response.data as any

		if (data.error && data.error.description) {
			message = data.error.description
		} else if (data.message) {
			message = data.message
		}
	} else if (error.request) {
		message = 'Сервер не отвечает, попробуйте позже'
	} else {
		message = 'Ошибка при настройке запроса'
	}
	toast.error(`Ошибка: ${message}`)

	// Прокидываем ошибку дальше
	return Promise.reject(error)
}

// Функция для обработки успешных ответов
const handleResponse = (response: AxiosResponse) => {
	return response
}

const handleRequest = async (config: InternalAxiosRequestConfig) => {
	const { default: store } = await import('./store')
	let token = localStorage.getItem('token')
	const refreshToken = localStorage.getItem('refresh_token')

	if (config.url === 'auth/refresh') {
		return config
	}

	if (window.location.pathname === '/auth') {
		return config
	}

	if (token && refreshToken) {
		if (isTokenExpiringSoon(token)) {
			try {
				token = await store.dispatch(refreshAccessToken(refreshToken)).unwrap()
			} catch (err) {
				logoutUser()
				// redirect('/login')
				window.location.href = '/auth'
				return Promise.reject(err)
			}
		}
	} else {
		logoutUser()
		// redirect('/login')
		window.location.href = '/auth'
		return Promise.reject(new Error('User is not authenticated'))
	}
	config.headers['Authorization'] = `Bearer ${token}`
	return config
}

const handleCheckAccessToken = async () => {
	const { default: store } = await import('./store')
	let token = localStorage.getItem('token')
	const refreshToken = localStorage.getItem('refresh_token')

	if (token && refreshToken) {
		try {
			return await store.dispatch(refreshAccessToken(refreshToken)).unwrap()
		} catch (err) {
			logoutUser()
			return Promise.reject(err)
		}
	}
}

const handleRequestError = (error: AxiosError) => {
	console.log('handleRequestError:', error)
	return Promise.reject(error)
}

// Устанавливаем перехватчики
axiosInstance.interceptors.response.use(handleResponse, handleError)
axiosInstance.interceptors.request.use(handleRequest, handleRequestError)

let authorized = (headers?: any) => ({
	// headers: { 'X-Authorization': accessToken }
	headers: { Authorization: `Bearer ${accessToken}`, ...headers }
})

// export const formPost = {
//     'Content-Type': "application/x-www-form-urlencoded'"
// };

// export const multipartFormPost = {
//     'Content-Type': "multipart/form-data"
// };

export const handleIOError = (reject?: (res: any) => void) => (error: any) => {
	console.error(error)
	if (!error.response) {
		logoutUser()
		accessToken = ''
		redirect('/auth')
		return
	}
	switch (error.response.status) {
		case 401:
			logoutUser()
			accessToken = ''
			redirect('/auth')
			return
	}

	if (reject) {
		reject(error)
	}
}

const setAccessToken = (token?: string) => {
	accessToken = token || ''
}

export default axiosInstance
export { authorized, getAccessToken, setAccessToken, handleCheckAccessToken }
