import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useSelector } from 'react-redux'

import Form from '../../../../primitives/Form'
import { getRequestStandard } from '../../../../store/slices/constants'
import { StructuredReportNames } from '../../../../store/slices/search/types'
import { StructuredSearchContext } from '../../index'

export type FormValues<T extends string | number | symbol> = {
	[K in T]: string
}

const Standard = () => {
	const { handleStructuredReport } = useContext(StructuredSearchContext)
	const requestStandard = useSelector(getRequestStandard)
	const [currentStandard, setCurrentStandard] = useState<string | null>(null)

	const handleSubmit = useCallback(
		(values: FormValues<StructuredReportNames>) => {
			return handleStructuredReport(values)
		},
		[handleStructuredReport]
	)

	const handleChange = (values: FormValues<StructuredReportNames>) => {
		if (values.method_type) setCurrentStandard(values.method_type)
	}

	const FIELDS = useMemo(
		() => [
			{
				name: 'requisites',
				children: [
					{
						name: 'method_type',
						placeholder: 'Метод',
						type: 'select',
						options: Object.values(requestStandard).map((item: any) => ({
							value: item.module,
							label: item.description
						})),
						initialValue: currentStandard
					}
				]
			}
		],
		[requestStandard, currentStandard]
	)

	useEffect(() => {
		if (!currentStandard || !requestStandard) return
		if (FIELDS[0].children.length) {
			FIELDS[0].children.splice(1)
		}

		const rows: any[] = Object.entries(
			Object.values(requestStandard).find(
				item => item?.module === currentStandard
			)?.fields
		).map(([key, value]) => ({
			name: key,
			placeholder: value
		}))
		FIELDS[0].children.push(...rows)
	}, [currentStandard, requestStandard])

	const VALIDATE_COMBINATIONS = [
		[{ name: 'method_type', errorLabel: 'Метод' }]
	] as never[]

	return (
		<div>
			<Form
				fields={FIELDS}
				onSubmit={handleSubmit}
				onChange={handleChange}
				buttonText='Искать'
				headerText={undefined}
				buttonIcon={undefined}
				column={undefined}
				className={undefined}
				validateCombinations={VALIDATE_COMBINATIONS}
			/>
		</div>
	)
}

export default React.memo(Standard)
