import { UilSearchAlt } from '@iconscout/react-unicons'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import SearchTable from '../../components/SearchTable'
import Form from '../../primitives/Form'
import { ROUTES_PATHS } from '../../router/routes'
import { AppDispatch, IRootState } from '../../store'
import { AUTH } from '../../store/slices/auth'
import { getTGBot } from '../../store/slices/constants'
import { SEARCH, getUnstructuredReport } from '../../store/slices/search'

import styles from './index.module.scss'

const Search = () => {
	const dispatch = useDispatch<AppDispatch>()
	const report = useSelector<IRootState, Object | null>(
		state => state[SEARCH].unstructured.entity
	)
	const [page, setPage] = useState(1)
	const authUser = useSelector((state: IRootState) => state[AUTH].entity)
	const auth = useSelector((state: IRootState) => state[AUTH])
	const tgBot = useSelector(getTGBot)

	const form = useMemo(
		() => [
			{
				name: 'unstructured',
				labelText: 'Введите запрос',
				placeholder: 'Капустин Игорь Вячеславович 09.02.1980',
				description: (
					<p>
						или используйте{' '}
						<Link to={ROUTES_PATHS.STRUCTURED_SEARCH.INDEX}>
							структурированный поиск
						</Link>
					</p>
				),
				required: true
			}
		],
		[]
	)

	const handleSearch = useCallback(
		({ unstructured }: { unstructured: string }) => {
			return dispatch(getUnstructuredReport(unstructured))
		},
		[]
	)

	return (
		<div className={styles.Search}>
			<Form
				fields={form}
				buttonIcon={<UilSearchAlt />}
				onChange={undefined}
				onSubmit={handleSearch}
				buttonText='Поиск'
				headerText={null}
				column={null}
				className={null}
			/>
			{report && (
				<SearchTable page={page} setPage={setPage} authUser={authUser} />
			)}
			{auth.entity?.telegram_id && !auth.entity?.telegram_chat_id && (
				<p style={{ margin: '12px 0 0 0' }}>
					Для работы через телеграм бота{' '}
					<a
						href={`https://t.me/${tgBot('ifce')}?start=invite_${
							auth.entity?.telegram_id
						}`}
					>
						<b>перейдите по ссылке</b>
					</a>
				</p>
			)}
			{auth.entity?.telegram_id && auth.entity?.telegram_chat_id && (
				<p style={{ margin: '12px 0 0 0' }}>
					Для изменения привязанного аккаунта к телеграм боту{' '}
					<a
						href={`https://t.me/${tgBot('ifce')}?start=invite_${
							auth.entity?.telegram_id
						}`}
					>
						<b>перейдите по ссылке</b>
					</a>
				</p>
			)}
		</div>
	)
}

export default React.memo(Search)
