import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import styles from './App.module.scss'
import Header from './components/Header'
import { AppDispatch, IRootState } from './store'
import { AUTH, IAuthState, logout } from './store/slices/auth'
import { loadConsts } from './store/slices/constants'

const App = () => {
	const dispatch = useDispatch<AppDispatch>()
	const user = useSelector<IRootState, IAuthState>(state => state[AUTH])

	useEffect(() => {
		dispatch(loadConsts())
	})

	const handleLogout = useCallback(() => dispatch(logout()), [])

	return (
		<>
			<Header user={user.entity} handleLogout={handleLogout} />
			<div className={styles.Container}>
				<Outlet />
			</div>
			<ToastContainer
				position='top-center'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
		</>
	)
}

export default React.memo(App)
